import { makeAutoObservable } from "mobx";
import { ZAttrViewInfo } from "src/common/attrView";
import { onError } from "src/common/onError";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import {
  loadObjectAttrinbutesReduced,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectRefSelector } from "src/common/attrEdit/components";
import { selectOnRowClick } from "../tables/selectOnRowClick";

export class ObjectRefSelectorStore {
  filtersPageStore: EntityFiltersPageStore | null = null;

  attrInfo: ZAttribute | null = null;

  setAttrInfo(data: ZAttribute) {
    this.attrInfo = data;
  }

  component: ZObjectRefSelector | null = null;

  setComponent(data: ZObjectRefSelector) {
    this.component = data;
  }

  viewInfo: ZAttrViewInfo | null = null;

  setViewInfo(data: ZAttrViewInfo) {
    this.viewInfo = data ?? null;
  }

  initialValues: string[] | null = null;

  setInitialValues(list: string[]) {
    this.initialValues = list;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  attsMetaMap: Record<number, ZAttribute> = {};

  setAttsMetaMap(map: Record<number, ZAttribute>) {
    this.attsMetaMap = map;
  }

  get labelAtts() {
    return this.component?.labelAtts;
  }

  values: string[] = [];

  setValues(list: string[]) {
    this.values = list;
  }

  async init(
    attrInfo: ZAttribute,
    component: ZObjectRefSelector,
    viewInfo: ZAttrViewInfo,
    multiple: boolean,
    value?: string[],
    onChange?: (value?: string[]) => void,
  ) {
    try {
      this.setLoading(true);

      const safeValues = value || [];
      const objectId = Number(attrInfo.referenceId);
      const attsMetaMap = makeObjectAttributesMap(
        await loadObjectAttrinbutesReduced(String(objectId)),
      );

      this.setAttrInfo(attrInfo);
      this.setComponent(component);
      this.setViewInfo(viewInfo);
      this.setInitialValues(safeValues);
      this.setAttsMetaMap(attsMetaMap);

      this.filtersPageStore = new EntityFiltersPageStore({
        objectId,
        selectionSettings: {
          keepSelected: true,
          onSelect: (rows, changed, isSelected) => {
            if (multiple) {
              const changedIds = changed.map((c) => String(c.id));
              const changedKeys = new Set(changedIds);
              const out = this.values.filter((v) => !changedKeys.has(v));
              const newVals = isSelected ? [...out, ...changedIds] : out;
              onChange?.(newVals);
            } else {
              onChange?.(rows.map((c) => String(c.id)));
            }
          },
          selectionType: multiple ? "checkbox" : "radio",
        },
        onLoad: () => {
          this.syncSelected(this.values);
        },
        canCreate: false,
        onRowClick: (row) => {
          const store = this.filtersPageStore?.tableStore;
          if (store) selectOnRowClick(row, store);
        },
      });
      this.filtersPageStore.init(objectId);
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  syncSelected(values: string[]) {
    const tableStore = this.filtersPageStore?.tableStore;
    if (!tableStore) return;
    const allRows = tableStore.rows;
    const needSelectKeys = new Set(values.map(Number));
    const needSelectRows = allRows.filter((row) => needSelectKeys.has(row.id));
    tableStore?.safeSelect(needSelectRows);
  }

  constructor() {
    makeAutoObservable(this);
  }
}
