// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__attachmentCard--_Lo4x {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 8px;
  max-width: 300px;
}
.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__imgAttCard--DtjaN {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__baseAttCard--xvMPj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__reply--LVqfr {
  margin-top: 8px;
  border-left: 1px solid #0000008c;
  color: #0000008c;
  background-color: #f9f9f9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/MessageList/MsgGroup/Attachment/Attachment.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;AADF;AAIA;EACE,eAAA;EACA,gCAAA;EACA,gBAAA;EACA,oCAAA;AAFF","sourcesContent":[".attachmentCard {\n  border: 1px solid #d9d9d9;\n  border-radius: 3px;\n  padding: 8px;\n  max-width: 300px;\n}\n\n.imgAttCard {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.baseAttCard {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n}\n\n.reply {\n  margin-top: 8px;\n  border-left: 1px solid #0000008c;\n  color: #0000008c;\n  background-color: #f9f9f9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachmentCard": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__attachmentCard--_Lo4x`,
	"imgAttCard": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__imgAttCard--DtjaN`,
	"baseAttCard": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__baseAttCard--xvMPj`,
	"reply": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-Attachment-Attachment-module__reply--LVqfr`
};
export default ___CSS_LOADER_EXPORT___;
