import * as React from "react";
import { onError } from "src/common/onError";

type PropsLinkView = {
  values: string[] | undefined;
};

export const LinkView: React.FC<PropsLinkView> = ({ values }) => {
  const ref = values?.[0];
  if (!ref) return null;
  let label = ref;
  try {
    label = decodeURIComponent(ref);
  } catch (e) {
    onError(e);
  }
  return (
    <a
      href={ref}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {label}
    </a>
  );
};
