import React from "react";
import { Avatar, Button, Typography } from "antd";
import styles from "./MsgGroup.module.less";
import { ZRCMessage } from "../../../rocketChat/rocketChat.types";
import { Msg } from "./Msg/Msg";
import { ChatFormStore } from "../../ChatFormStore";

type PropsMsgGroup = {
  msgGroup: ZRCMessage[];
  store: ChatFormStore;
};

export const MsgGroup: React.FC<PropsMsgGroup> = ({ msgGroup, store }) => {
  const user = msgGroup[0]?.u;
  return (
    <div className={styles.msgGroup}>
      <Avatar icon={user?.username[0]} />
      <div className={styles.msgGroupContent}>
        <Typography.Text type="secondary">{user?.username}</Typography.Text>
        <div className={styles.msgGroupMsgList}>
          {msgGroup.map(({ msg, attachments, _id }, msgIndex) => (
            <div key={_id}>
              <Msg
                // eslint-disable-next-line react/no-array-index-key
                key={msgIndex}
                attachments={attachments || []}
                text={msg}
              />
              <Button type="text" onClick={() => store.setReplyMsgId(_id)}>
                <Typography.Text type="secondary">Reply</Typography.Text>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
