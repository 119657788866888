import React, { useEffect, useState } from "react";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { getIdNames } from "src/references/getIdNames";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { onError } from "src/common/onError";
import { classNames } from "src/common/classNames";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import { SimpleText } from "../SimpleText/SimpleText";
import { seedSeparators } from "../../viewFormItems/Separator";
import styles from "./LinkStd.module.less";

type PropsLinkStd = {
  viewInfo: ZAttrViewInfo | undefined;
  values?: string[];
  attrMeta: ZAttrMeta;
};

export const LinkStd: React.FC<PropsLinkStd> = ({
  values,
  viewInfo,
  attrMeta,
}) => {
  const [attrTypeMap, setTypeMap] = useState<Record<number, string>>({});
  const init = async () => {
    setTypeMap(makeDictNameById(await getIdNames("attrType")));
  };
  useEffect(() => {
    try {
      init();
    } catch (error) {
      onError(error);
    }
  }, []);

  const linkCreateMap: Record<string, (value: string) => string> = {
    [AttrTypeName.image]: (value: string) => imgSrc(value),
    [AttrTypeName.object]: (value: string) =>
      makeUrl(PageUrl.entityCard, { id: value }),
  };

  const linkFn = linkCreateMap[attrTypeMap[attrMeta.valueType] || ""];
  if (!linkFn) return "невозможно создать ссылку";

  const labelsWithSeparator = seedSeparators(
    values?.map((val) => ({
      content: (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <a
          key={val}
          href={linkFn(val)}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          rel="noreferrer"
          style={{ width: "100%" }}
        >
          {val}
        </a>
      ),
      key: val,
    })) ?? [],
    viewInfo?.appearance?.view?.separator,
  );

  return (
    <SimpleText
      className={classNames([styles.box])}
      values={labelsWithSeparator}
      viewInfo={viewInfo}
    />
  );
};

LinkStd.defaultProps = {
  values: undefined,
};
