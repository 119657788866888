import { rest } from "src/common/rest";
import { ZAttribute, ZAttributeValue, zAttribute } from "src/types/ZAttribute";
import { ZGroup, zGroup } from "src/types/ZGroup";
import { ZRestriction, zRestriction } from "src/types/ZRestriction";
import { ZIdName } from "src/types/ZIdName";
import { onError } from "src/common/onError";
import { ZValueThings, zValueThings } from "src/types/ZValueThings";
import { apiObjUrl } from "src/common/apiUrl";
import { optionalLangParams } from "src/lang/langHdr";
import {
  ZLightGroup,
  ZLightObject,
  zLightGroup,
  zLightObject,
} from "./Obj2Tab/Obj2Nodes";
import { ZObjectItem, zObjectItem } from "../../types/ZObjectItem";

export const loadObjects = async (): Promise<ZObjectItem[]> => {
  const response = await rest.get(apiObjUrl("/objects"));
  return zObjectItem.array().parse(response.data);
};

export const createObject = async (
  newObject: Omit<ZObjectItem, "id">,
): Promise<ZObjectItem> => {
  const resp = await rest.post(apiObjUrl(`/objects`), newObject);
  return zObjectItem.parse(resp.data);
};

export const updateObject = async (
  srcObject: Omit<ZObjectItem, "attributes" | "groups">,
  svData: Partial<ZObjectItem>,
): Promise<ZObjectItem> => {
  const { id } = srcObject;
  await rest.put(apiObjUrl(`/objects/${id}`), { ...svData, id });
  const resp = await rest.get(apiObjUrl(`/objects/${id}`));
  const newObj = zObjectItem.parse(resp.data);
  return newObj;
};

export const createLightObject = async (
  newObject: Omit<ZLightObject, "id">,
): Promise<ZLightObject> => {
  const resp = await rest.post(apiObjUrl(`/objects`), newObject);
  return zLightObject.parse(resp.data);
};

export const saveLightObject = async (
  objectId: number,
  values: Partial<ZLightObject>,
): Promise<ZLightObject> => {
  await rest.put(apiObjUrl(`/objects/${objectId}`), {
    ...values,
    id: objectId,
  });
  const resp = await rest.get(apiObjUrl(`/objects/${objectId}`));
  const newObj = zLightObject.parse(resp.data);
  return newObj;
};

export const deleteObject = async (objId: number) => {
  await rest.delete(apiObjUrl(`/objects/${objId}`));
};

export const loadObjectAttrinbutesAll = async (
  objectId: number | string,
  options: { translate?: boolean } = {},
) => {
  const attsResp = await rest.get(
    apiObjUrl(`/objects/${objectId}/all-attributes`),
    optionalLangParams(options.translate),
  );
  return zAttribute.array().parse(attsResp.data);
};

export const loadObjectAttrinbutesReduced = async (objectId: string) => {
  const attsResp = await rest.get(apiObjUrl(`/objects/${objectId}/attributes`));
  return zAttribute.array().parse(attsResp.data);
};

export const makeObjectAttributesMap = (list: ZAttribute[]) => {
  const objectAttsMap = list.reduce(
    (acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    },
    {} as Record<number, ZAttribute>,
  );
  return objectAttsMap;
};

export const createAttributeInObject = async (
  objId: number,
  attr: Omit<ZAttribute, "id">,
): Promise<ZAttribute> => {
  const resp = await rest.post(apiObjUrl(`/objects/${objId}/attributes`), attr);
  return zAttribute.parse(resp.data);
};

export const deleteAttributeFromObject = async (
  objId: number,
  attrId: number,
) => {
  await rest.delete(apiObjUrl(`/objects/${objId}/attributes/${attrId}`));
};

export const deleteAttribute = async (attrId: number) => {
  await rest.delete(apiObjUrl(`/attributes/${attrId}`));
};

export const updateAttribute = async (
  attr: ZAttribute,
): Promise<ZAttribute> => {
  await rest.put(apiObjUrl(`/attributes/${attr.id}`), attr);
  await rest.get(apiObjUrl(`/attributes/${attr.id}/restrictions`));
  const resp = await rest.get(apiObjUrl(`/attributes/${attr.id}`));
  return zAttribute.parse(resp.data);
};

export const createGroupInObj = async (
  objId: number,
  group: Omit<ZGroup, "id">,
): Promise<ZGroup> => {
  const resp = await rest.post(apiObjUrl(`/objects/${objId}/groups`), group);
  return zGroup.parse(resp.data);
};

export const createGroupInGroup = async (
  ownerGroupId: number,
  group: Omit<ZGroup, "id">,
): Promise<ZGroup> => {
  const resp = await rest.post(
    apiObjUrl(`/groups/${ownerGroupId}/groups`),
    group,
  );
  return zGroup.parse(resp.data);
};

export const updateGroup = async (group: ZLightGroup): Promise<ZGroup> => {
  await rest.put(apiObjUrl(`/groups/${group.id}`), group);
  const resp = await rest.get(apiObjUrl(`/groups/${group.id}`));
  return { ...zLightGroup.parse(resp.data), attributeId: group.attributeId };
};

export const createAttrInGroup = async (
  groupId: number,
  attr: Omit<ZAttribute, "id">,
): Promise<ZAttribute> => {
  const resp = await rest.post(
    apiObjUrl(`/groups/${groupId}/attributes`),
    attr,
  );
  return zAttribute.parse(resp.data);
};

export const deleteGroup = async (groupId: number) => {
  await rest.delete(apiObjUrl(`/groups/${groupId}`));
};

export const loadRestrictions = async (
  attrId: number,
): Promise<ZRestriction | null> => {
  const resp = await rest.get(apiObjUrl(`/attributes/${attrId}/restrictions`));
  if (!resp.data) return null;
  return zRestriction.parse(resp.data);
};

export const saveRestrictions = async (
  attrId: number,
  restrictions: ZRestriction,
) => {
  await rest.put(apiObjUrl(`/attributes/${attrId}/restrictions`), restrictions);
};

export const loadDictValues = async (
  dictAttrId: number,
): Promise<ZAttributeValue[] | undefined> => {
  try {
    const resp = await rest.get(apiObjUrl(`/attributes/${dictAttrId}`));
    const attr = zAttribute.parse(resp.data);
    return attr.values ?? [];
  } catch (e) {
    onError(e);
    return undefined;
  }
};

export const loadDict = async (dictAttrId: number): Promise<ZIdName[]> => {
  try {
    const resp = await rest.get(apiObjUrl(`/attributes/${dictAttrId}`));
    const attr = zAttribute.parse(resp.data);
    return (attr.values ?? []).map(({ id, value }) => ({
      id,
      name: String(value),
    }));
  } catch (e) {
    onError(e);
    return [];
  }
};

export const loadFromAttr = async (attrId: number): Promise<ZIdName[]> => {
  try {
    const resp = await rest.get(apiObjUrl(`/attributes/${attrId}`));
    const attr = zAttribute.parse(resp.data);
    const { referenceId } = attr;
    if (!referenceId)
      throw Error(`Атрибут ${attr.name} не содержит справочника`);
    return loadDict(referenceId);
  } catch (e) {
    onError(e);
  }
  return [];
};

export const loadValueThings = async (
  groupId: number,
  valueId: number,
): Promise<ZValueThings> => {
  const resp = await rest.get(
    apiObjUrl(`/groups/${groupId}/values/${valueId}`),
  );
  return zValueThings.parse(resp.data);
};

export const apiAddExistingAttr = async (
  groupId: number,
  valueId: number,
  attrId: number,
): Promise<void> => {
  await rest.post(
    apiObjUrl(`/groups/${groupId}/values/${valueId}/attributes/${attrId}`),
  );
};

export const createAttrInValue = async (
  groupId: number,
  valueId: number,
  body: Omit<ZAttribute, "id">,
): Promise<ZAttribute> => {
  const resp = await rest.post(
    apiObjUrl(`/groups/${groupId}/values/${valueId}/attributes`),
    body,
  );
  return zAttribute.parse(resp.data);
};

export const createGroupInValue = async (
  groupId: number,
  valueId: number,
  body: Omit<ZGroup, "id">,
) => {
  const resp = await rest.post(
    apiObjUrl(`/groups/${groupId}/values/${valueId}/groups`),
    body,
  );
  return zLightGroup.parse(resp.data);
};
