import { rest } from "src/common/rest";
import { apiMeasurementChart } from "src/common/apiUrl";
import { zMesChartSettings, ZMesChartSettings } from "./MesChartTypes";

export const loadMesChartSettings = async (servObjId: number) => {
  const resp = await rest.get(apiMeasurementChart(`/settings/${servObjId}`));
  return zMesChartSettings.parse(resp.data);
};

export const saveMesChartSettings = async (
  servObjId: number,
  data: ZMesChartSettings,
) => {
  await rest.put(apiMeasurementChart(`/settings/${servObjId}`), data);
};
