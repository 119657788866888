// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G .ant-tabs-top {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G .ant-tabs-content-holder,
.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G .ant-tabs-content,
.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G .ant-tabs-tabpane {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__layout--kSebf {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/Obj2Tab.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;AALA;EAOI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAXA;;;EAgBI,OAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAIA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AAFF","sourcesContent":[".box {\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n\n  :global(.ant-tabs-top) {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n  }\n\n  :global(.ant-tabs-content-holder),\n  :global(.ant-tabs-content),\n  :global(.ant-tabs-tabpane) {\n    flex: 1;\n    overflow-y: auto;\n    height: 100%;\n  }\n}\n\n.layout {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__box--FiV8G`,
	"layout": `src-pages-ManagementPage-Obj2Tab-Obj2Tab-module__layout--kSebf`
};
export default ___CSS_LOADER_EXPORT___;
