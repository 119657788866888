import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Avatar, Button, Form, Space, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SendOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { onError } from "src/common/onError";
import styles from "./ChatForm.module.less";
import { ChatFormStore, RCFormData } from "./ChatFormStore";
import { MessageList } from "./MessageList/MessageList";
import { RCUploader } from "./RCUploader";
import { rcUrl } from "../apiChat";

/**
 * Таким образом происходит реплай.
 * Это было выяснено опытным путем, лучше такой опыт не повторять...
 * Сейчас стало понятно, что в реплай нужно указывать ОРИГИНАЛЬНЫЙ адрес чата, при чем вместе с портом...
 * то есть под прикрытием nginx это работать не будет
 */
export const prepMsgReply = (rid: string, msgId: string, msg: string) =>
  `[ ](${window.location.origin}${rcUrl("")}/channel/${rid}?msg=${msgId})\n${msg}`;

type PropsChatForm = {
  store: ChatFormStore;
  headerExtra: React.ReactNode;
};

export const ChatForm: React.FC<PropsChatForm> = observer(
  ({ store, headerExtra }) => {
    const [form] = useForm();

    useEffect(() => {
      store.init();
    }, [store]);

    if (store.loading) return <Spin spinning />;

    const handleSubmit = async (data: RCFormData) => {
      try {
        const containInfo = data.message || data.file;
        if (!containInfo) return;
        const actualMsg = store.replyMsgId
          ? prepMsgReply(store.chatId, store.replyMsgId, data.message)
          : data.message;
        await store.sendMessage({ ...data, message: actualMsg });
        store.setReplyMsgId("");
        form.resetFields();
      } catch (error) {
        onError(error);
      }
    };

    return (
      <div className={styles.box}>
        <Space className={styles.header} align="center">
          <Space align="center">
            <Avatar icon={store.chatName[0]} />
            <div className={styles.headerInfo}>
              <Typography.Text strong>{store.chatName}</Typography.Text>
              <Typography.Text type="secondary">
                участники: {store.membersCount}
              </Typography.Text>
            </div>
          </Space>
          {headerExtra}
        </Space>
        <MessageList store={store} />
        <Form<RCFormData>
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          className={styles.form}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item name="message">
              <TextArea placeholder="Сообщение..." style={{ resize: "none" }} />
            </Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" size="small">
                <SendOutlined />
                Отправить сообщение
              </Button>
              <Form.Item name="file">
                <RCUploader />
              </Form.Item>
            </Space>
          </Space>
        </Form>
      </div>
    );
  },
);
