import * as React from "react";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { rest } from "src/common/rest";
import { ModelessFormStore } from "src/components/ModelessForm";
import { ZGroup, ZIdGroupType, zGroup, zIdGroupType } from "src/types/ZGroup";
import { ZAttribute, ZAttributeValue, zAttribute } from "src/types/ZAttribute";
import { getIdNames } from "src/references/getIdNames";
import { onError } from "src/common/onError";
import { AttrType } from "src/pages/CategoryRefPage/CategoryAttributes/AttrLevelPanel/modals/AttrType";
import { FormInstance } from "antd";
import { apiObjUrl } from "src/common/apiUrl";
import {
  apiReadTranslations,
  apiSaveTranslations,
} from "src/common/api/apiTranslation";
import { ZTranslation } from "src/types/ZTranslation";
import {
  deleteNegativeItems,
  newItemId,
} from "./GroupsTreeView/groupsTreeUtils";
import { buildGroupsTree } from "./GroupsTreeView/buildGroupsTree";
import { GroupsTreeItem } from "./GroupsTreeView/GroupsTreeItem";
import { findByAttrId } from "./findByAttrId";
import { EdDict, attr2edDict, edDict2attr } from "./AttrFields2/EdDict";
/* eslint no-plusplus: "off" */

export class Dict2TabStore {
  constructor() {
    this.formStore = new ModelessFormStore(
      (values: unknown) => this.save(values),
      () => this.removeNewItem(),
    );

    makeAutoObservable(this);
  }

  formStore: ModelessFormStore;

  data: RemoteData<ZGroup[]> = { status: "none" };

  setData(newData: RemoteData<ZGroup[]>) {
    this.data = newData;
  }

  get groupsList(): ZGroup[] {
    return this.data.status === "ready" ? this.data.result : [];
  }

  get treeData(): GroupsTreeItem[] {
    return buildGroupsTree(this.groupsList);
  }

  refreshTree() {
    if (this.data.status === "ready") {
      this.data.result = [...this.data.result];
    }
  }

  dictGroupType: ZIdGroupType | null = null;

  setDictGroupType(t: ZIdGroupType | null) {
    this.dictGroupType = t;
  }

  attrValueType = 1;

  setAttrValueType(t: number) {
    this.attrValueType = t;
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      const [resp, groupTypesRaw, attrTypes] = await Promise.all([
        rest.get(apiObjUrl("/groups"), {
          params: { groupTypeName: "Dictionary" },
        }),
        getIdNames("groupType"),
        getIdNames("attrType"),
      ]);
      const groupTypes = zIdGroupType.array().parse(groupTypesRaw);
      this.setDictGroupType(
        groupTypes.find(({ name }) => name === "Dictionary") ?? null,
      );
      if (!this.dictGroupType)
        throw Error("Справочник типов групп не содержит Dictionary");

      const strAttrType = attrTypes.find(
        ({ name }) => name === AttrType.string,
      );
      if (!strAttrType) throw Error("Не найден строковый тип");
      this.setAttrValueType(strAttrType.id);

      const result = zGroup.array().parse(resp.data);
      this.setData({ status: "ready", result });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async save(values: unknown) {
    const { currentEntity } = this;
    if (currentEntity) {
      if ("groupType" in currentEntity) {
        await this.saveGroup(currentEntity, values);
      } else {
        await this.saveAttr(currentEntity, values);
      }
    }
  }

  async saveGroup(curGroup: ZGroup, values: unknown) {
    const validValues = zGroup.partial().parse(values);
    const combined = { ...curGroup, ...validValues };
    const { id, ...partial } = combined;
    if (id === newItemId) {
      const resp = await rest.post(apiObjUrl("/groups"), partial);
      const newGroup = zGroup.parse(resp.data);
      this.groupPostUpdate(newGroup, id);
    } else {
      await rest.put(apiObjUrl(`/groups/${id}`), combined);
      this.groupPostUpdate(combined, id);
    }
  }

  groupPostUpdate(newGroup: ZGroup, oldId: number) {
    const { data } = this;
    if (data.status === "ready") {
      const result = data.result.map((gr) => (gr.id === oldId ? newGroup : gr));
      this.setData({ status: "ready", result });
      const item = this.treeData.find(({ id }) => id === newGroup.id);
      this.unsafeSelect(item);
    }
  }

  async saveAttr(curAttr: ZAttribute, values: unknown) {
    this.clearBatch();
    const { attr: validValues, translations } = edDict2attr(
      values as EdDict,
      curAttr,
    );

    const combined = {
      ...curAttr,
      ...validValues,
      values: validValues.values?.map(({ id, value }) => ({
        id: id === newItemId ? undefined : id,
        value,
      })),
    };
    const { id, ...partial } = combined;
    let newAttr: ZAttribute;
    if (id === newItemId) {
      const attrInfo = findByAttrId(id, this.groupsList);
      if (!attrInfo) throw Error("Ошибка при создании нового атрибута");
      const resp = await rest.post(
        apiObjUrl(`/groups/${attrInfo.group.id}/attributes`),
        partial,
      );
      newAttr = zAttribute.parse(resp.data);
    } else {
      const url = apiObjUrl(`/attributes/${id}`);
      await rest.put(url, combined);
      const resp = await rest.get(url);
      newAttr = zAttribute.parse(resp.data);
    }
    await apiSaveTranslations(translations);
    this.attrPostUpdate(newAttr, id);
  }

  attrPostUpdate(newAttr: ZAttribute, oldId: number) {
    const { data } = this;
    if (data.status === "ready") {
      const res = findByAttrId(oldId, data.result);
      if (res && res.group.attributes) {
        const pos = res.group.attributes.findIndex(({ id }) => id === oldId);
        if (pos >= 0) res.group.attributes[pos] = newAttr;
        this.refreshTree();
      }
    }
  }

  // expand
  expanded = new Set<React.Key>();

  setExpanded(list: React.Key[]) {
    this.expanded = new Set(list);
  }

  changeExpand(key: React.Key, action: "add" | "delete" | "toggle") {
    let realAction = action;
    if (action === "toggle") {
      realAction = this.expanded.has(key) ? "delete" : "add";
    }
    if (realAction === "delete") {
      this.expanded.delete(key);
    } else {
      this.expanded.add(key);
    }
  }

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  onExpand(keys: React.Key[]) {
    this.setExpanded(keys);
  }

  // selection

  currentItem: GroupsTreeItem | null = null;

  setCurrentItem(item: GroupsTreeItem | null) {
    this.currentItem = item;
  }

  get selectedKeys(): React.Key[] {
    const { currentItem } = this;
    return currentItem ? [currentItem.key] : [];
  }

  get currentEntity(): ZAttribute | ZGroup | undefined {
    const { currentItem, data } = this;
    if (data.status === "ready") {
      if (currentItem?.type === "group") {
        return data.result.find(({ id }) => id === currentItem.id);
      }
      if (currentItem?.type === "attr") {
        return findByAttrId(currentItem.id, data.result)?.attr;
      }
    }
    return undefined;
  }

  onSelect(keys: React.Key[], item: GroupsTreeItem) {
    this.formStore.safeAction(() => {
      this.unsafeSelect(keys[0] ? item : null);
    });
  }

  lockCounter = 0;

  lock() {
    this.lockCounter++;
  }

  unlock() {
    this.lockCounter--;
  }

  get isContentLoading(): boolean {
    return this.lockCounter > 0;
  }

  unsafeSelect(item: GroupsTreeItem | null | undefined) {
    this.clearBatch();
    this.setCurrentItem(item ?? null);

    const { currentEntity } = this;
    if (!currentEntity) return;
    if ("groupType" in currentEntity) {
      this.formStore.safeLoad(
        this.currentEntity,
        undefined,
        item?.id === newItemId,
      );
      return;
    }
    // загрузка переводов
    const loadAttr = async (): Promise<ZTranslation[]> => {
      const phrases = (currentEntity.values ?? [])
        .filter(({ value }) => !!value)
        .map(({ value }) => value as string);
      return phrases.length > 0 ? apiReadTranslations(phrases) : [];
    };

    this.formStore.safeAction(() => {
      this.lock();
      this.formStore.unsafeLoad(attr2edDict(currentEntity));
      loadAttr()
        .then((res) => {
          this.formStore.unsafeLoad(attr2edDict(currentEntity, res));
        })
        .catch(onError)
        .finally(() => this.unlock());
    });
  }

  safeCreateGroup() {
    this.formStore.safeAction(() => {
      const groupType = this.dictGroupType;
      if (!groupType) throw Error("Не установлен тип группы");
      const newGroup: ZGroup = {
        id: newItemId,
        name: "",
        groupType,
      };
      const { data } = this;
      if (data.status === "ready") {
        data.result.push(newGroup);
        this.unsafeSelect(this.treeData.find(({ id }) => id === newGroup.id));
      }
    });
  }

  removeNewItem() {
    if (this.data.status === "ready") {
      deleteNegativeItems(this.data.result);
    }
  }

  safeAddAttr() {
    this.formStore.safeAction(() => {
      this.unsafeAddAttr();
    });
  }

  unsafeAddAttr() {
    const { currentEntity } = this;
    if (currentEntity && "groupType" in currentEntity) {
      const newAttr: ZAttribute = {
        id: newItemId,
        name: "",
        valueType: this.attrValueType,
        values: [],
      };
      currentEntity.attributes = [...(currentEntity.attributes ?? []), newAttr];
      const grItem = this.treeData.find(({ id }) => id === currentEntity.id);
      if (grItem) {
        const attrItem = grItem.children?.find(({ id }) => id === newItemId);
        if (attrItem) {
          this.setCurrentItem(attrItem);
          this.formStore.unsafeLoad(newAttr, true);
          this.changeExpand(grItem.key, "add");
        }
      } else {
        this.setCurrentItem(null);
        this.formStore.unsafeLoad(null);
      }
    }
  }

  deleteState: "none" | "show" | "work" = "none";

  setDeleteState(state: "none" | "show" | "work") {
    this.deleteState = state;
  }

  get messageForDelete(): string {
    return this.currentItem?.type === "attr"
      ? "Удалить арибут?"
      : "Удалить группу?";
  }

  startDeleteCurNode() {
    this.setDeleteState("show");
  }

  async doDeleteCurNode() {
    try {
      const { currentItem } = this;
      if (currentItem) {
        if (currentItem.id === newItemId) {
          this.removeNewItem();
        } else {
          this.setDeleteState("work");
          if (currentItem.type === "group") {
            await this.deleteGroup(currentItem.id);
          } else if (currentItem.type === "attr") {
            await this.deleteAttribute(currentItem.id);
          }
        }
        this.setCurrentItem(null);
        this.formStore.unsafeLoad(null);
      }
      this.setDeleteState("none");
    } catch (e) {
      onError(e);
      this.setDeleteState("show");
    }
  }

  protected async deleteGroup(groupId: number) {
    await rest.delete(apiObjUrl(`/groups/${groupId}`));
    if (this.data.status === "ready") {
      this.setData({
        status: "ready",
        result: this.data.result.filter(({ id }) => groupId !== id),
      });
    }
  }

  protected async deleteAttribute(attrId: number) {
    await rest.delete(apiObjUrl(`/attributes/${attrId}`));
    const { data } = this;
    if (data.status === "ready") {
      const res = findByAttrId(attrId, data.result);
      if (res) {
        res.group.attributes?.splice(res.attrIndex, 1);
      }
    }
  }

  // --- вставка нескольких значений
  clearBatch() {
    this.setBatchValues("");
    this.clearStatuses();
  }

  batchValues = "";

  setBatchValues(value: string) {
    if (this.pasteCounter > 0) {
      this.batchValues = (value || "").trim();
      this.pasteCounter--;
    } else {
      this.batchValues = value;
    }
  }

  get batchDisabled(): boolean {
    return !this.batchValues;
  }

  pasteCounter = 0;

  onPaste() {
    this.pasteCounter++;
  }

  statuses: Record<string, "double" | "new" | undefined> = {};

  setStatus(value: string, status: "double" | "new" | undefined) {
    this.statuses[value] = status;
  }

  clearStatuses() {
    this.statuses = {};
  }

  startBatch(form: FormInstance): { ignored: number; added: number } {
    let ignored = 0;
    let added = 0;
    const srcItems: string[] = this.batchValues
      .split("\n")
      .map((s) => s.trim())
      .filter((s) => !!s);
    this.clearStatuses();
    if (srcItems.length > 0) {
      srcItems.forEach((srcItem) => {
        const dstValues: ZAttributeValue[] = form.getFieldValue("values") ?? [];
        const doubleInDst = dstValues.find(({ value }) => value === srcItem);
        if (doubleInDst) {
          ignored++;
          this.setStatus(srcItem, "double");
        } else {
          form.setFieldValue("values", [
            ...dstValues,
            { id: -1, value: srcItem },
          ]);
          added++;
          this.setStatus(srcItem, "new");
        }
        this.setBatchValues("");
      });
    }
    return { added, ignored };
  }
}
