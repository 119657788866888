import * as React from "react";
import { t } from "i18next";
import { Form, FormRule, Input } from "antd";
import {
  blockItem,
  createNestContext,
  FormBlockDef,
  FormBlockItem,
} from "src/components/FormWithBlocks";
import { EntityGroupInnerBlock } from "src/pages/EntityCardPage/EntityGroupBlock";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { DrawItems } from "src/components/DrawItems";
import { appStore } from "src/appStore";
import { makeBlockId } from "src/components/FormWithBlocks/blockTypes/makeBlockId";
import { EdPasswordChange } from "./EdPasswordChange";
import styles from "./PasswordChangeBlock.module.less";

interface CheckRule {
  label: string;
  check: (
    value: string,
    ctx: {
      login: string;
    },
  ) => boolean;
}
const makeCheckRules = (): CheckRule[] => [
  {
    label: t("PasswordProps.at least N characters", { count: 8 }),
    check: (value: string): boolean => value.length >= 8,
  },
  {
    label: t("PasswordProps.contain numbers", { n: "(0-9)" }),
    check: (value: string): boolean => /\d/.test(value),
  },
  {
    label: t("PasswordProps.lowercase Latin letters", { n: "(a-z)" }),
    check: (value: string): boolean => /[a-z]/.test(value),
  },
  {
    label: t("PasswordProps.uppercase Latin letters", { n: "(A-Z)" }),
    check: (value: string): boolean => /[A-Z]/.test(value),
  },
  {
    label: `${t("PasswordProps.special characters")} (!@#$%+=&?*"{}^_.,:;<>/)`,
    check: (value: string): boolean => /[!@#$%+=&?*"{}^_.,:;<>/]/.test(value),
  },
  {
    label: t("PasswordProps.password cannot be the same as login"),
    check: (value, ctx) => value !== ctx.login,
  },
];

let checkRules: CheckRule[] | undefined;
const getCheckRules = (): CheckRule[] => {
  if (checkRules) return checkRules;
  const rules = makeCheckRules();
  checkRules = rules;
  return checkRules;
};

const sPassword = "password";

const passBlockField = (key: keyof EdPasswordChange) => [sPassword, key];
const shortPassField = (key: keyof EdPasswordChange) => key;

const PasswordCheck: React.FC = () => {
  const newPass = Form.useWatch(passBlockField("newPass")) ?? "";
  const ctx = {
    login: appStore.userInfo.userName,
  };
  return (
    <div className={styles.passBlock}>
      <div>{t("Password must contain")}:</div>
      {getCheckRules().map(({ label, check }) => (
        <div key={label} className={styles.rule}>
          {check(newPass, ctx) ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
};

const validatePass: FormRule = {
  validator: (_, value: string | undefined): Promise<void> => {
    if (value) {
      const ctx = {
        login: appStore.userInfo.userName,
      };
      const wrong = getCheckRules().find(({ check }) => !check(value, ctx));
      if (wrong) {
        return Promise.reject(
          Error(t("PasswordProps.Not done", { n: wrong.label })),
        );
      }
    }
    return Promise.resolve();
  },
};

const validatePass2: FormRule = ({ getFieldValue }) => ({
  validator: (_rule, value: string | undefined) =>
    value !== getFieldValue(passBlockField("newPass"))
      ? Promise.reject(Error(t("Doesn't match")))
      : Promise.resolve(),
});

export const passwordChangeBlock = (): FormBlockDef => ({
  key: sPassword,
  title: t("Change password"),
  render(prevId, ctx) {
    return (
      <EntityGroupInnerBlock title={this.title} id={makeBlockId(this.key)}>
        <DrawItems
          items={this.items ?? []}
          ctx={createNestContext(ctx, sPassword)}
        />
      </EntityGroupInnerBlock>
    );
  },
  items: [
    blockItem(
      shortPassField("oldPass"),
      t("Current password"),
      Input.Password,
      { autoComplete: "current-password" },
      { rules: [{ required: true }] },
    ),
    {
      key: "check",
      render: () => <PasswordCheck />,
      itemProps: { className: styles.emptyBlock },
    } satisfies FormBlockItem,
    {
      key: "empty3",
      render: () => <div />,
      itemProps: { className: styles.emptyBlock },
    } satisfies FormBlockItem,
    blockItem(
      shortPassField("newPass"),
      t("New password"),
      Input.Password,
      { autoComplete: "new-password" },
      { rules: [{ required: true }, validatePass] },
    ),
    blockItem(
      shortPassField("newPass2"),
      t("Repeat new password"),
      Input.Password,
      { autoComplete: "new-password" },
      {
        rules: [{ required: true }, validatePass2],
        dependencies: [passBlockField("newPass")],
      },
    ),
  ],
});
