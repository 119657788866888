import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { optionalLangParams } from "src/lang/langHdr";
import { zServiceInfo, ZServiceInfo } from "src/types/ZServiceInfo";
import { makeDictionary } from "src/common/makeDictionary";
import {
  ObjectServiceExt,
  zObjectService,
  ZObjectService,
} from "./businessServises.types";

export const loadServicesList = async (): Promise<ZServiceInfo[]> => {
  if (servCache) return servCache;
  const resp = await rest.get(apiObjUrl("/business-services"));
  const list = zServiceInfo.array().parse(resp.data);
  servCache = list;
  return list;
};
let servCache: ZServiceInfo[] | null = null;

export const loadServicesForObject = async (
  objectId: number | string,
  options: {
    translate?: boolean;
  } = {},
): Promise<ObjectServiceExt[]> => {
  const resp = await rest.get(
    apiObjUrl(`/business-services/${objectId}/services`),
    optionalLangParams(options.translate),
  );
  const raw = zObjectService.array().parse(resp.data);
  const list = await loadServicesList();
  const dict = makeDictionary(list, ({ id }) => id);
  const result: ObjectServiceExt[] = [];
  raw.forEach((src) => {
    const def = dict[src.serviceId];
    if (def) {
      result.push({
        ...src,
        serviceKey: def.name,
      });
    }
  });
  return result;
};

export const createServiceForObject = async (
  data: Omit<ZObjectService, "id">,
): Promise<ZObjectService> => {
  const resp = await rest.post(apiObjUrl("/business-services"), data);
  return zObjectService.parse(resp.data);
};

export const deleteServiceForObject = async ({
  objectId,
  serviceId,
}: ZObjectService): Promise<void> => {
  await rest.delete(apiObjUrl("/business-services"), {
    params: {
      objectId,
      serviceId,
    },
  });
};
