import React from "react";
import { ATVTemplateToken } from "../types";
import { ATVHeaderStd } from "./ATVHeaderStd/ATVHeaderStd";
import { ATVContainerStd } from "./ATVContainerStd/ATVContainerStd";
import { ATVRowStd } from "./ATVRowStd/ATVRowStd";

export const atvTokenStd: Required<ATVTemplateToken> = {
  header({ store, useSelection }) {
    return <ATVHeaderStd store={store} useSelection={useSelection} />;
  },
  container(_, children) {
    return <ATVContainerStd>{children}</ATVContainerStd>;
  },
  item({ store, onRowClick, useSelection }, row, rowKey) {
    return (
      <ATVRowStd
        store={store}
        onClick={onRowClick}
        useSelection={useSelection}
        row={row}
        rowKey={rowKey}
      />
    );
  },
};
