// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-MessageList-MessageList-module__msgList--H0Y1M {
  flex: 1;
  padding: 15px 0;
  overflow: auto;
  overflow-anchor: auto;
  display: flex;
  flex-direction: column-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/MessageList/MessageList.module.less"],"names":[],"mappings":"AAAA;EACE,OAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;EACA,aAAA;EACA,8BAAA;AACF","sourcesContent":[".msgList {\n  flex: 1;\n  padding: 15px 0;\n  overflow: auto;\n  overflow-anchor: auto;\n  display: flex;\n  flex-direction: column-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msgList": `src-businessServices-services-bsChat-ChatForm-MessageList-MessageList-module__msgList--H0Y1M`
};
export default ___CSS_LOADER_EXPORT___;
