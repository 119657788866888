import { ZAttribute } from "src/types/ZAttribute";
import { ZRestriction } from "src/types/ZRestriction";
import { ZAttrEditInfo, zAttrEditInfo } from "src/common/attrEdit";
import { ZAttrViewInfo, zAttrViewInfo } from "src/common/attrView";
import { ZTranslationMap } from "src/types/ZTranslation";
import {
  AttrRestrictions,
  cvtRestrictionsDictToFlat,
  cvtRestrictionsFlatToDict,
} from "./forms/RestrictionsTable/restrictionUtils";
import { EdObjStates } from "./EdObject";

/**
 * Тип данных для описания атрибута ZAttribute
 * Но для формы редактирования он не подходит.
 * Поэтому форма использует тип EdAttribute
 */
export type EdAttribute = Omit<
  ZAttribute,
  "values" | "viewStyles" | "viewType"
> & {
  useRestriction: boolean; // если false, то attributeValueRestriction=null
  masterAttrId?: number; // id атрибута, от которого зависит этот атрибут
  restrictions?: AttrRestrictions;
  editorInfo?: ZAttrEditInfo; // формируется из viewStyles
  viewInfo?: ZAttrViewInfo; // формируется из viewType
  rolesMap: EdObjStates;
  translations?: ZTranslationMap;
};

// чтобы использовать вместо строк в <Form.Item name="...">
export const edAttrField = {
  fieldKey: "fieldKey",
  masterAttrId: "masterAttrId",
  name: "name",
  referenceId: "referenceId",
  restrictions: "restrictions",
  useRestriction: "useRestriction",
  valueType: "valueType",
  editorInfo: "editorInfo",
  viewInfo: "viewInfo",
  rolesMap: "rolesMap",
  roleIds: "roleIds",
  translations: "translations",
  iterator: "iterator",
} as const satisfies Partial<Record<keyof EdAttribute, string>>;

export const attr2edit = (
  attr: ZAttribute,
  rolesMap: EdObjStates,
  restrictions?: ZRestriction | null,
  translations?: ZTranslationMap,
): EdAttribute => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { values, viewStyles, viewType, ...other } = attr;
  const result: EdAttribute = {
    ...other,
    useRestriction: !!restrictions,
    masterAttrId: restrictions?.restrictionAttributeId,
    restrictions: cvtRestrictionsFlatToDict(restrictions?.values ?? []),
    rolesMap,
    translations,
  };
  // Распарсить информацию о компоненте редактирования. Она поступает в текстовом виде
  try {
    if (viewStyles) {
      result.editorInfo = zAttrEditInfo.parse(JSON.parse(viewStyles));
    }
    if (viewType) {
      result.viewInfo = zAttrViewInfo.parse(JSON.parse(viewType));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return result;
};

type ResEdit2Attr = {
  attr: Partial<ZAttribute>;
  restrictions: ZRestriction | null;
  rolesMap: EdObjStates;
  translations?: ZTranslationMap;
};
export const edit2attr = (edAttr: EdAttribute): ResEdit2Attr => {
  const {
    useRestriction,
    masterAttrId,
    restrictions,
    editorInfo,
    viewInfo,
    rolesMap,
    translations,
    ...other
  } = edAttr;
  const zres: ZRestriction | null =
    useRestriction && masterAttrId && restrictions
      ? {
          restrictionAttributeId: masterAttrId,
          values: cvtRestrictionsDictToFlat(restrictions),
        }
      : null;
  // На форме есть поле editorInfo.component.editor. И если оно пусто, то нужно вообще убрать component. Иначе не проходит zod-валидация
  const validEditorInfo = editorInfo ? { ...editorInfo } : undefined;
  if (validEditorInfo && !validEditorInfo.component?.editor) {
    delete validEditorInfo.component;
  }
  // На форме есть поле viewInfo.component.view. И если оно пусто, то нужно вообще убрать component. Иначе не проходит zod-валидация
  const validViewInfo = viewInfo ? { ...viewInfo } : undefined;
  if (validViewInfo && !validViewInfo.component?.view) {
    delete validViewInfo.component;
  }
  return {
    attr: {
      ...other,
      viewStyles: JSON.stringify(
        zAttrEditInfo.parse({ ...validEditorInfo, ver: 1 }),
      ),
      viewType: JSON.stringify(
        zAttrViewInfo.parse({ ...validViewInfo, ver: 1 }),
      ),
    },
    restrictions: zres,
    rolesMap,
    translations,
  };
};
