import { AttrTypeName } from "src/types/AttrType";
import { findNodeOwnerByKey } from "src/common/findNodeOwnerByKey";
import { GroupType, ZGroup } from "src/types/ZGroup";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZAttribute } from "src/types/ZAttribute";
import { AttributeO2, CommonNodeO2 } from "./Obj2Tab/Obj2Nodes";

export type ObjIteratorMode = "disabled" | "enabled" | "hidden";

export const isIteratorAvailable = (
  attrNode: AttributeO2,
  typeName: AttrTypeName, // Нельзя брать тип из атрибута, т.к. он может меняться на форме
  tree: CommonNodeO2[],
): ObjIteratorMode => {
  if (typeName !== AttrTypeName.object) {
    // Неподходящий тип атрибута
    return "hidden";
  }
  let curKey = attrNode.key;
  for (;;) {
    const res = findNodeOwnerByKey(curKey, tree);
    if (!res) return "hidden";
    const { owner } = res;
    if (owner.type === "obj") break;
    // Считаем что если атрибут внутри значения, то он не может быть итератором.
    if (owner.type === "value") return "disabled";
    curKey = owner.key;
  }
  // TODO: Хорошо бы сюда ещё добавить проверку на уникальность. Чтобы нельзя было сделать два итератора в одном объекте.
  return "enabled";
};

const iteratorInAttributes = (
  attributes: ZAttribute[] | null | undefined,
): boolean => !!attributes?.find(({ iterator }) => iterator);
const iteratorInGroup = ({
  groupType,
  attributes,
  groups,
}: ZGroup): boolean => {
  if (groupType.name !== GroupType.Mnemonic) return false;
  if (iteratorInAttributes(attributes)) return true;
  return iteratorInGroups(groups);
};
const iteratorInGroups = (groups: ZGroup[] | null | undefined): boolean =>
  !!groups?.find(iteratorInGroup);

export const hasIterator = (obj: ZObjectItem): boolean => {
  if (iteratorInAttributes(obj.attributes)) {
    return true;
  }
  return iteratorInGroups(obj.groups);
};
