import { z } from "zod";
import {
  FnLoad,
  SelectionSetings,
  TableLoadParams,
  TableResponse,
  TableStore,
} from "src/components/tables/TableStore";
import { AxiosRequestConfig } from "axios";
import { rest } from "src/common/rest";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { AColumn } from "src/components/tables/AsyncTable";
import { loadRefDict } from "src/common/loadRefDict";
import { onError } from "src/common/onError";
import { AttrTypeName } from "src/types/AttrType";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { ZAttrViewInfo, getViewInfo } from "src/common/attrView";
import React from "react";
import { ZEntity, zEntity } from "src/types/ZEntity";
import { ObjectStateLabel } from "src/common/attrView/components/";
import {
  loadObjectStates,
  makeObjectStatesMap,
} from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import { AttrTypeMap, loadAttrTypeMap } from "src/common/attributes";
import { ZAttribute } from "src/types/ZAttribute";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { formatParams } from "src/components/tables/utils/formatParams";
import { apiObjUrl } from "src/common/apiUrl";
import { t } from "i18next";
import { GroupCell } from "./GroupCell/GroupCell";

/*
Данное соглашение достигнуто в результате диалога с бэкендом
договорились, что статус имеет фиктивный id = -1
*/
const createStateCol = (statesLabels: Record<number, string>): AColumn => ({
  key: "-1",
  title: t("State"),
  render(_, record) {
    const { objectId, stateId } = record as ZEntity;
    return stateId ? (
      <ObjectStateLabel statesLabels={statesLabels} stateId={stateId} />
    ) : (
      objectId
    );
  },
});

const loadTableData = <TFilters extends object>(
  url: string,
  params: TableLoadParams<TFilters>,
  config?: AxiosRequestConfig,
) =>
  rest.post(url, params.filters, {
    params: formatParams(params),
    ...config,
    paramsSerializer: {
      indexes: null,
    },
  });

const preloadRefs = async (
  attrMetaMap: Record<number, ZAttribute>,
  typeMap: AttrTypeMap,
) => {
  try {
    const uniqueRefs: Record<number, Promise<unknown>> = {};
    Object.values(attrMetaMap).forEach((meta) => {
      const isDict =
        typeMap[meta.valueType] === AttrTypeName.dictMulti ||
        typeMap[meta.valueType] === AttrTypeName.dictSingle;
      if (meta.referenceId && isDict) {
        uniqueRefs[meta.referenceId] = loadRefDict(meta.referenceId, {
          translate: true,
        });
      }
    });
    const uniqueRefsList = Object.values(uniqueRefs);
    await Promise.all(uniqueRefsList);
  } catch (error) {
    onError(error);
  }
};

const mapEntity2TableRow = (
  entity: ZEntity,
  attrMetaMap: Record<number, ZAttribute>,
) => ({
  id: entity.id,
  stateId: entity.stateId,
  objectId: entity.objectId,
  ...entity.attributeValues.reduce(
    (acc, curr) => {
      const meta = attrMetaMap[curr.attributeId];
      const key = meta?.id;
      if (key) acc[key] = curr.values || [];
      return acc;
    },
    {} as Record<string, string[]>,
  ),
});

export const entityList2Rows = (
  entityList: ZEntity[],
  attrMetaMap: Record<number, ZAttribute>,
) => entityList.map((e) => mapEntity2TableRow(e, attrMetaMap));

const schemaTableData = z.object({
  totalElements: z.number(),
  content: zEntity.array(),
});

const entityTableLoaderStd =
  <TRow extends {}, TFilters extends {}>(
    attrMetaMap: Record<number, ZAttribute>,
  ): FnLoad<TRow, TFilters> =>
  async (params: TableLoadParams<TFilters>): Promise<TableResponse<TRow>> => {
    // получаем список entity
    const respTableData = await loadTableData(
      apiObjUrl("/entities/search"),
      params,
    );
    const entityTableData = schemaTableData.parse(respTableData.data);
    const mappedEntityListRaw = entityList2Rows(
      entityTableData.content,
      attrMetaMap,
    );
    return {
      totalItems: entityTableData.totalElements,
      rows: mappedEntityListRaw as unknown as TRow[],
    };
  };

type ColumnsConfig = {
  withoutStatus?: boolean;
};

export const createColsFromAttrMeta = (
  attrMetaList: ZAttrMeta[],
  attrTypeMap: AttrTypeMap,
  statesLabels: Record<number, string>,
  config?: ColumnsConfig,
): AColumn[] => {
  const viewInfoMap = attrMetaList.reduce(
    (acc, curr) => {
      const info = getViewInfo(curr.viewType);
      if (info) acc[String(curr.id)] = info;
      return acc;
    },
    {} as Record<string, ZAttrViewInfo>,
  );

  const groupMap = attrMetaList.reduce(
    (acc, curr) => {
      const attrViewInfo = getViewInfo(curr.viewType);
      const key = attrViewInfo?.appearance?.column?.group.name;
      if (key) {
        if (!acc[key]) acc[key] = [];
        acc[key]?.push(curr);
      }
      return acc;
    },
    {} as Record<string, ZAttrMeta[]>,
  );

  const colsWithGroups = Object.values(groupMap).map((attrList) => {
    const key = attrList.map((attr) => attr.id).join("/");
    const title = attrList.map((attr) => attr.name).join("/");
    return {
      key,
      title,
      render(_: string[], row: Record<string, string[]>) {
        return (
          <GroupCell
            attrMetaList={attrList}
            attrTypeMap={attrTypeMap}
            viewInfoMap={viewInfoMap}
            row={row}
          />
        );
      },
    };
  });
  const attsWithoutGroup = attrMetaList.filter(
    (attr) => !viewInfoMap[attr.id]?.appearance?.column?.group.name,
  );

  const hasSorter = (attr: ZAttribute) => !attr.referenceId;
  const cols: AColumn[] = attsWithoutGroup.map((attrMeta) => {
    const viewInfo = getViewInfo(attrMeta.viewType);
    return {
      // сортировку добавляем только столбцам с простыми типами атрибутов
      sorter: hasSorter(attrMeta),
      key: String(attrMeta.id),
      dataIndex: String(attrMeta.id),
      title: attrMeta.name,
      render(values) {
        return createItemView(attrMeta, attrTypeMap, values, viewInfo);
      },
    };
  });
  const finalCols = [createStateCol(statesLabels), ...cols, ...colsWithGroups];
  if (config?.withoutStatus) finalCols.shift();
  return finalCols;
};

const createSettingKey = (id: number) => `entityTableSettings${id}`;
export const compoundEntityTableStore = async <
  TRow extends {},
  TFilters extends {},
>(
  objectId: number,
  rowKey: keyof TRow,
  initialParams?: Partial<TableLoadParams<TFilters>>,
  selectionSettings?: SelectionSetings<TRow>,
  onLoad?: () => void,
) => {
  const attrTypeMap = await loadAttrTypeMap();
  const attrMetaMap = makeObjectAttributesMap(
    await loadObjectAttrinbutesAll(String(objectId), { translate: true }),
  );
  // Заранее загружаем справочники в кэш, чтобы не было постепенной загрузки внутри таблицы
  await preloadRefs(attrMetaMap, attrTypeMap);
  const statesMap = makeObjectStatesMap(
    await loadObjectStates(objectId, { translate: true }),
  );
  const columns = createColsFromAttrMeta(
    Object.values(attrMetaMap),
    attrTypeMap,
    statesMap,
  );
  const tableStore = new TableStore<TRow, TFilters>({
    rowKey,
    initialParams,
    fnLoad: entityTableLoaderStd(attrMetaMap),
    settingsKey: createSettingKey(objectId),
    selectionSettings,
    onLoad,
    resizableColumns: true,
  });
  tableStore.setColumns(columns);
  return tableStore;
};
