import { notification, SelectProps } from "antd";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { ZGroup, zGroup } from "src/types/ZGroup";
import { apiObjUrl } from "src/common/apiUrl";
import { t } from "i18next";

export class SelectDictionaryStore {
  constructor() {
    makeAutoObservable(this);
  }

  wait = false;

  setWait(flag: boolean) {
    this.wait = flag;
  }

  groups: ZGroup[] = [];

  setGroups(list: ZGroup[]) {
    this.groups = list;
  }

  async init() {
    try {
      this.setWait(true);
      const resp = await rest.get(apiObjUrl("/groups"), {
        params: { groupTypeId: 1 },
      });
      const newGroups = zGroup.array().parse(resp.data);
      this.setGroups(newGroups);
      checkGroups(newGroups);
    } catch (e) {
      onError(e);
    } finally {
      this.setWait(false);
    }
  }

  get dictOptions(): SelectProps["options"] {
    return this.groups.map(({ name, attributes }) => ({
      title: name,
      label: name,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      options: attributes?.map(({ name, id }) => ({
        label: name,
        value: id,
        id: `dict-opt-${id}`,
      })),
    }));
  }

  value: number | null = null;

  setValue(value: number | null | undefined) {
    this.value = value ?? null;
  }
}

const checkGroups = (groups: ZGroup[]) => {
  let dblName = "";
  const usedAttrIds = new Set<number>();
  groups.forEach((g) => {
    g.attributes?.forEach(({ id, name }) => {
      if (usedAttrIds.has(id)) dblName = name;
      usedAttrIds.add(id);
    });
  });
  if (dblName)
    notification.warning({
      message: t("Attention!"),
      description: `Получены некорректные данные со списком справочников. Дублируется "${dblName}". Это приводит к неправильной работе компонента выбора справочника.`,
    });
};
