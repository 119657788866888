import * as React from "react";
import {
  BlockContext,
  FormBlockDef,
  RenderResult,
  FormBlockItem,
} from "src/components/FormWithBlocks";
import { ZAttribute } from "src/types/ZAttribute";
import { GroupType, ZGroup } from "src/types/ZGroup";
import { makeId } from "src/components/FormWithBlocks/blockTypes/makeId";
import { makeBlockId } from "src/components/FormWithBlocks/blockTypes/makeBlockId";
import { createItem2 } from "./createItem2";
import { EntityGroupBlock } from "../EntityGroupBlock";
import { dependentGroup } from "./dependentGroup";
import { BuilderCtx } from "./BuilderCtx";

/**
 * Нестандартный блок, который включает простые элементы и рекурсивно вложенные блоки
 */
export const buildMnenoGroup = (
  key: string,
  title: string,
  attributes: ZAttribute[],
  groups: ZGroup[] | null | undefined,
  ctx: BuilderCtx,
): FormBlockDef => {
  const items: FormBlockItem[] = attributes.map((a) => createItem2(a, ctx));
  const groupsList = groups ?? [];
  const subBlocks: FormBlockDef[] = [];
  groupsList.forEach((gr) => {
    if (gr.groupType.name === GroupType.Dictionary) {
      subBlocks.push(dependentGroup(gr, ctx));
    } else if (gr.groupType.name === GroupType.Mnemonic) {
      subBlocks.push(
        buildMnenoGroup(
          String(gr.id),
          gr.name,
          gr.attributes ?? [],
          gr.groups,
          ctx,
        ),
      );
    }
  });
  return createEntityGroupBlock(key, title, items, subBlocks);
};

export const createEntityGroupBlock = (
  key: string,
  title: string,
  items: FormBlockItem[],
  subBlocks: FormBlockDef[],
): FormBlockDef => ({
  key,
  title,
  subBlocks,
  items,
  render(prevId: string, blockCtx: BlockContext): RenderResult {
    return (
      <EntityGroupBlock
        title={title || ""}
        prevId={makeId(prevId, key)}
        ctx={blockCtx}
        items={items}
        subBlocks={subBlocks}
        id={makeBlockId(key)}
      />
    );
  },
});
