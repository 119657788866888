import * as React from "react";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react-lite";
import { appStore } from "src/appStore";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { notification } from "antd";
import { UserAccountPageStore } from "./userAccountPageStore";
import { EntityAnchor } from "../EntityCardPage/EntityFormWithAnchor/EntityAnchor";

const idUserAccountFormContainer = "UserAccountFormContainer";

interface PropsUserAccountForm {
  store: UserAccountPageStore;
}

export const UserAccountForm: React.FC<PropsUserAccountForm> = observer(
  ({ store }) => {
    const [form] = useForm();
    React.useEffect(() => {
      form.setFieldsValue(store.initialData);
    }, [store.initialData]);
    const { rootBlock } = store;
    if (!rootBlock) return null;
    return (
      <TShapeForm
        form={form}
        title={appStore.userInfo.userName}
        formProps={{
          block: rootBlock,
          name: "UserAccountForm",
          store: store.formStore,
          submitText: t("Save"),
          submit: async (data) => store.changePassword(data),
          onSuccess: () => {
            notification.success({ message: t("Password change completed") });
          },
        }}
        leftPart={
          <EntityAnchor
            anchors={store.anchors}
            containerSelector={`#${idUserAccountFormContainer}`}
          />
        }
        rightPartProps={{ id: idUserAccountFormContainer }}
      />
    );
  },
);
