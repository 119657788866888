import * as React from "react";
import { Form, notification } from "antd";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { observer } from "mobx-react-lite";
import { EdCardValues } from "src/pages/EntityCardPage/apiEntityCard";
import { t } from "i18next";
import { TShapeForm } from "../TShapeForm/TShapeForm";

const entityCardFormName = "EntitySubForm";
const idEntityFormContainer = "EntitySubFormContainer";

interface PropsChildEntitiesEditor {
  store: EntityCardStore;
  onSuccess(): void;
}

export const msgMultiInstancesCreated = (count: number) =>
  t("New instances were created", { count });

export const ChildEntitiesEditor: React.FC<PropsChildEntitiesEditor> = observer(
  (props) => {
    const { store, onSuccess } = props;
    const { rootBlock, formStore, entityName } = store;
    const [form] = Form.useForm();
    if (!rootBlock) return null;
    const onSubmit = async (values: EdCardValues) => store.create(values);
    const onSuccessInternal = (values: EdCardValues | EdCardValues[]) => {
      const message = Array.isArray(values)
        ? msgMultiInstancesCreated(values.length)
        : t("New instance has been created");
      notification.success({ message });
      onSuccess();
    };
    return (
      <TShapeForm
        title={entityName}
        form={form}
        formProps={{
          name: entityCardFormName,
          initialData: store.initialData,
          block: rootBlock,
          store: formStore,
          submit: onSubmit,
          submitText: t("Add"),
          onSuccess: onSuccessInternal,
        }}
        rightPartProps={{ id: idEntityFormContainer }}
      />
    );
  },
);
