import React from "react";
import { ZRCAttachemntImg } from "src/businessServices/services/bsChat/rocketChat/rocketChat.types";
import { Button, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { createFileAccessUrl } from "./createFileAccessUrl";
import styles from "./Attachment.module.less";

type PropsImgAttachment = { data: ZRCAttachemntImg };

export const ImgAttachment: React.FC<PropsImgAttachment> = ({ data }) => (
  <div className={classNames([styles.attachmentCard, styles.imgAttCard])}>
    <img src={createFileAccessUrl(data.image_url)} alt="" />
    <Typography.Text strong style={{ textAlign: "center" }}>
      {data.title}
    </Typography.Text>
    <Button href={createFileAccessUrl(data.image_url)} download={data.title}>
      <DownloadOutlined />
    </Button>
  </div>
);
