import React, { useEffect, useMemo, useState } from "react";
import { TableStore } from "src/components/tables/TableStore";
import { Button, Empty, Modal } from "antd";
import { RetweetOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { makeAutoObservable } from "mobx";
import { loadAvailableStates } from "src/pages/EntityCardPage/apiEntityCard";
import { ZEntityAvailableState } from "src/pages/EntityCardPage/EntityPage.types";
import { EntityStates } from "src/pages/EntityCardPage/EntityFormWithAnchor/EntityStates";
import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { onError } from "src/common/onError";
import { loadObjectStates } from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import { t } from "i18next";
import { CurStateView } from "src/pages/EntityCardPage/EntityHistory/CurStateView";
import { ZEntityFilters, ZEntityRow } from "../types";

const createStore = () =>
  makeAutoObservable({
    allStates: undefined as ZEntityAvailableState[] | undefined,
    setAllStates(list: ZEntityAvailableState[] | undefined) {
      this.allStates = list;
    },

    stateNames: {} as Record<string, string>,
    setStateNames(names: Record<string, string>) {
      this.stateNames = names;
    },

    async init(stateId: number, objectId: number) {
      const availableStates = stateId
        ? await loadAvailableStates(objectId, stateId, { translate: true })
        : undefined;
      this.setAllStates(availableStates);
      const states = await loadObjectStates(objectId, { translate: true });
      this.setStateNames(
        states.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
          {} as Record<number, string>,
        ),
      );
    },
  });

type PropsChangeStateBulkButton = {
  store: TableStore<ZEntityRow, ZEntityFilters>;
  objectId: number;
};

export const ChangeStateBulkButton: React.FC<PropsChangeStateBulkButton> =
  observer(({ store, objectId }) => {
    const [open, setOpen] = useState(false);
    const storeCmp = useMemo(() => createStore(), []);
    const firstState = store.selected[0]?.stateId;
    const curStateName = storeCmp.stateNames[firstState || ""];
    const curStateView = curStateName ? (
      <CurStateView name={curStateName} />
    ) : undefined;
    const wrong =
      !!store.selected.find(({ stateId }) => stateId !== firstState) ||
      firstState === undefined;

    const hasNextSteps = !!storeCmp.allStates?.length;

    useEffect(() => {
      if (firstState) storeCmp.init(firstState, objectId);
    }, [firstState]);

    const handleChange = async (stateId: number | string) => {
      try {
        await rest.put(apiObjUrl("/entities/update-states"), {
          stateId,
          entities: store.selected.map((row) => row.id),
        });
        await store.reload().then(() => {
          /**
           * копирует поведение метода updateSelected из TableStore
           * за небольшим исключением - тут не производится проверка на keepSelected
           * но если не обновить выбранные тут строки, то в selected будет храниться старая инфа
           */
          const oldSelected = new Set(
            store.selected.map((row) => row[store.rowKey]),
          );
          store.safeSelect(
            store.rows.filter((row) => oldSelected.has(row[store.rowKey])),
          );
        });
      } catch (error) {
        onError(error);
      } finally {
        setOpen(false);
      }
    };

    return (
      <>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          size="large"
          disabled={wrong}
        >
          <RetweetOutlined /> {t("Change of state")}
        </Button>
        <Modal
          title={t("ChangingTheStateOfAnInstance", { count: 0 })}
          open={open}
          onCancel={() => setOpen(false)}
          footer
        >
          {hasNextSteps ? (
            <EntityStates
              currentState={curStateView}
              availableStates={storeCmp.allStates}
              onChangeState={handleChange}
            />
          ) : (
            <Empty description={t("No available states for transition")} />
          )}
        </Modal>
      </>
    );
  });
