/**
 * Блок полей редактирования свойств компонента на карточке сущности.
 * Зависит от типа атрибута.
 * У разных типов есть свой набор возможных компонентов.
 */
import * as React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import { AttrTypeName } from "src/types/AttrType";
import {
  AttrEditorCompName,
  ZAttrItemProps,
  editorsByType,
} from "src/common/attrEdit";
import DisabledContext from "antd/es/config-provider/DisabledContext";
import { ZOption } from "src/types/ZOption";
import { EditOutlined, EyeOutlined, FormOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { FormInstance } from "rc-field-form";
import { getTooltipMode } from "src/components/ExtendedTooltip/getTooltipMode";
import { ZTooltipExtFields } from "src/components/ExtendedTooltip";
import { loadObjectAttrinbutesReduced } from "src/pages/ManagementPage/objectsApi";
import { edAttrField } from "../../../EdAttribute";
import { SelectAttributeFlat } from "../SelectAttributeFlat/SelectAttributeFlat";
import { ComponentPreview } from "./ComponentPreview";
import { RegexpInput } from "./RegexpInput/RegexpInput";
import { validatorRegexpInput } from "./RegexpInput";
import { TooltipExtEdit } from "./TooltipExtEdit/TooltipExtEdit";
import { dateCmpOptions } from "./dateCmpOptions";
import styles from "./EditorInfo.module.less";
import { RelativeCmpEdit } from "./RelativeCmpEdit";
import { SelectAttributesRolesList } from "./ViewInfo/SelectAttributesRolesList";

interface PropsEditorInfo {
  attrTypesDict: Record<number, string>;
  disabled: boolean;
  objectId: number;
  curAttrId: number;
}

const root = edAttrField.editorInfo;
const compField = (name: string) => [root, "component", name];
const itemField = (name: keyof ZAttrItemProps) => [root, "itemProps", name];
const editorField = compField("editor");
const relativeCmpTypes: string[] = [
  AttrTypeName.date,
  AttrTypeName.dateTime,
  AttrTypeName.time,
  AttrTypeName.int,
  AttrTypeName.number,
];

export const EditorInfo: React.FC<PropsEditorInfo> = (props) => {
  const { attrTypesDict, disabled, objectId, curAttrId } = props;
  const [openPreview, setOpenPreview] = React.useState(false);
  const [tooltipExtOpen, setTooltipExtOpen] = React.useState(false);
  const form = Form.useFormInstance();
  const attrType = Form.useWatch(edAttrField.valueType);
  const multiple = Form.useWatch(compField("multiple"));
  const refId = Form.useWatch(edAttrField.referenceId);
  const roleIds = Form.useWatch(edAttrField.roleIds) || [];
  const tooltipExt = Form.useWatch(itemField("tooltipExt")) ?? {};
  const tooltipText = Form.useWatch(itemField("tooltip"));
  const isIterator = Form.useWatch(edAttrField.iterator);
  const typeName = attrTypesDict[attrType] ?? String(attrType);
  const curEditor: AttrEditorCompName | undefined = Form.useWatch(editorField);
  const componentNames: ZOption[] = React.useMemo(() => {
    const editors: string[] = editorsByType[typeName as AttrTypeName] ?? [];
    if (curEditor && !editors.includes(curEditor)) {
      form.resetFields(editorField);
    }
    return editors.map((value) => ({ value, label: value }));
  }, [attrType]);

  const isMultiple =
    curEditor === "Uploader" ||
    curEditor === "PersonSelect" ||
    curEditor === "ImageCarousel";
  const isMaxCount =
    (curEditor === "Select" && typeName === AttrTypeName.dictMulti) ||
    (curEditor === "Uploader" && multiple) ||
    (curEditor === "ImageCarousel" && multiple);
  const isMaxSize = curEditor === "Uploader" || curEditor === "ImageCarousel";
  const isMaxLength = curEditor === "Input" || curEditor === "TextArea";
  const isMinMax = curEditor === "InputNumber";
  const isPrecision =
    curEditor === "InputNumber" && typeName === AttrTypeName.number;
  const isAccept = curEditor === "Uploader" || curEditor === "ImageCarousel";
  const isMaxImageHeight = curEditor === "ImageCarousel";
  const isAddon =
    curEditor === "Input" ||
    curEditor === "InputNumber" ||
    curEditor === "MaskedInput";
  const isObjectRef =
    curEditor === "ObectRefSelector" || curEditor === "ObjectRefTable";
  const isChildEntities = curEditor === "ChildEntities";
  const isMask = curEditor === "MaskedInput";
  const isMinMaxRows = curEditor === "TextArea";
  const isTodayRule =
    typeName === AttrTypeName.date || typeName === AttrTypeName.dateTime;
  const isRelativeCmpRule = relativeCmpTypes.includes(typeName);
  const isPersonInfo = curEditor === "PersonSelect";

  React.useEffect(() => {
    if (isIterator) form.setFieldValue(compField("multiple"), true);
  }, [isIterator]);

  return (
    <DisabledContext.Provider value={disabled}>
      <div className={classNames([styles.box, styles.editor])}>
        <div className={styles.boxTitle}>
          <FormOutlined /> На карточке экземпляра (форма)
        </div>
        <div className={styles.fields}>
          <Form.Item name={editorField} label="Компонент">
            <Select allowClear options={componentNames} />
          </Form.Item>
          <Form.Item
            name={itemField("required")}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Является обязательным для заполнения</Checkbox>
          </Form.Item>

          {isMask && (
            <Form.Item
              name={compField("mask")}
              label="Маска"
              rules={[{ required: true }]}
              tooltip={
                <a
                  href="https://imask.js.org/guide.html#masked-pattern"
                  target="_blank"
                  rel="noreferrer"
                >
                  Подробное описание правил
                </a>
              }
            >
              <Input allowClear />
            </Form.Item>
          )}
          {isMaxLength && (
            <Form.Item
              name={compField("maxLength")}
              label="Максимальное количество символов"
            >
              <InputNumber precision={0} />
            </Form.Item>
          )}
          {isMinMax && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("min")} label="Минимальное значение">
                <InputNumber />
              </Form.Item>
              <Form.Item name={compField("max")} label="Максимальное значение">
                <InputNumber />
              </Form.Item>
            </div>
          )}
          {isMultiple && (
            <Form.Item
              name={compField("multiple")}
              valuePropName="checked"
              noStyle
            >
              <Checkbox>Множественный выбор</Checkbox>
            </Form.Item>
          )}
          {isMaxCount && (
            <Form.Item
              name={compField("maxCount")}
              label="Максимальное количество выбираемых элементов"
            >
              <InputNumber min={1} precision={0} />
            </Form.Item>
          )}
          {isMaxSize && (
            <Form.Item
              name={compField("maxSize")}
              label="Максимальный размер файлов"
            >
              <InputNumber min={1} precision={0} addonAfter="Мб" />
            </Form.Item>
          )}
          {isPrecision && (
            <Form.Item
              name={compField("precision")}
              label="Число знаков после запятой(точки)"
            >
              <InputNumber />
            </Form.Item>
          )}
          {isAccept && (
            <Form.Item
              name={compField("accept")}
              label="Фильтр на типы файлов"
              tooltip="Пример: .doc,.docx,application/msword"
            >
              <Input allowClear />
            </Form.Item>
          )}
          {isMaxImageHeight && (
            <Form.Item
              name={compField("imageHeight")}
              label="Высота изображения"
            >
              <InputNumber min={1} addonAfter="px" />
            </Form.Item>
          )}
          {isAddon && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("addonBefore")} label="Префикс">
                <Input allowClear />
              </Form.Item>
              <Form.Item name={compField("addonAfter")} label="Суффикс">
                <Input allowClear />
              </Form.Item>
            </div>
          )}
          {isMinMaxRows && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("minRows")} label="Мин. строк">
                <InputNumber min={1} precision={0} />
              </Form.Item>
              <Form.Item
                name={compField("maxRows")}
                label="Макс. строк"
                dependencies={[compField("minRows")]}
                rules={[
                  (formInst: FormInstance) => ({
                    validator: (_, value) => {
                      const minRows = formInst.getFieldValue(
                        compField("minRows"),
                      );
                      if (minRows && value && minRows > value)
                        return Promise.reject(
                          Error("не может быть меньше Мин. значения"),
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={1} precision={0} />
              </Form.Item>
            </div>
          )}
          {isObjectRef && (
            <>
              <Form.Item
                name={compField("multiple")}
                valuePropName="checked"
                noStyle
              >
                <Checkbox disabled={isIterator}>Множественный выбор</Checkbox>
              </Form.Item>
              <Form.Item
                label="Атрибуты для отображения"
                name={compField("labelAtts")}
                rules={[{ required: true }]}
              >
                <SelectAttributeFlat
                  loader={loadObjectAttrinbutesReduced}
                  objectId={refId}
                  mode="multiple"
                  maxTagCount="responsive"
                />
              </Form.Item>
            </>
          )}
          {isChildEntities && (
            <Form.Item
              label="Связанный атрибут"
              name={compField("attrId")}
              rules={[{ required: true }]}
            >
              <SelectAttributeFlat
                objectId={refId}
                loader={async (objId) => {
                  const attrList = await loadObjectAttrinbutesReduced(objId);
                  const attsObjRef = attrList.filter(
                    (attr) =>
                      attrTypesDict[attr.valueType] === AttrTypeName.object,
                  );
                  return attsObjRef;
                }}
              />
            </Form.Item>
          )}
          {isPersonInfo && (
            <SelectAttributesRolesList
              rolesIds={roleIds}
              name={compField("rolesViewAtts")}
            />
          )}
          <Divider className={styles.divider}>Дополнительные параметры</Divider>
          <div className={styles.threeCols}>
            <Form.Item
              label="Колонки"
              name={itemField("colspan")}
              tooltip="Сколько колонок займёт компонент на карточке экземпляра"
            >
              <InputNumber min={1} max={3} />
            </Form.Item>
            <Form.Item
              label="Строки"
              name={itemField("rowspan")}
              tooltip="Сколько ячеек по вертикали займёт компонент на карточке экземпляра"
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name={itemField("newLine")}
              label="С новой строки"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </div>
          {typeName === AttrTypeName.string && (
            <Form.Item
              label="Шаблон для проверки значения"
              name={itemField("pattern")}
              rules={[validatorRegexpInput]}
            >
              <RegexpInput />
            </Form.Item>
          )}
          <Form.Item name={itemField("tooltipExt")} hidden>
            <ExtTooltipItem
              tooltipText={tooltipText}
              open={tooltipExtOpen}
              close={() => setTooltipExtOpen(false)}
            />
          </Form.Item>
          <Form.Item name={itemField("tooltip")} label="Всплывающая подсказка">
            <Input
              allowClear
              addonAfter={
                <Button
                  size="small"
                  type="text"
                  icon={
                    getTooltipMode({ tooltipExt }) === "ext" ? (
                      <FormOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  onClick={() => setTooltipExtOpen(true)}
                />
              }
            />
          </Form.Item>
          {isTodayRule && (
            <Form.Item
              name={itemField("todayRule")}
              label="Ограничение относительно сегодня"
            >
              <Select options={dateCmpOptions()} allowClear />
            </Form.Item>
          )}
          {isRelativeCmpRule && (
            <Form.Item
              name={itemField("relativeCmpRule")}
              label="Сравнение с другим полем"
            >
              <RelativeCmpEdit
                typeName={typeName}
                objectId={objectId}
                curAttrId={curAttrId}
                typesMap={attrTypesDict}
              />
            </Form.Item>
          )}
          <div>
            <Button
              className={styles.viewButton}
              icon={<EyeOutlined />}
              onClick={() => setOpenPreview(true)}
            >
              Просмотр
            </Button>
            <ComponentPreview
              open={openPreview}
              close={() => setOpenPreview(false)}
              typesMap={attrTypesDict}
              attrForm={form}
            />
          </div>
        </div>
      </div>
    </DisabledContext.Provider>
  );
};

interface PropsExtTooltipItem {
  open: boolean;
  close(): void;
  value?: ZTooltipExtFields;
  onChange?(newValue: ZTooltipExtFields | undefined): void;
  tooltipText: string | undefined;
}

const ExtTooltipItem: React.FC<PropsExtTooltipItem> = (props) => {
  const { open, close, value, onChange, tooltipText } = props;
  const form = Form.useFormInstance();
  return (
    <TooltipExtEdit
      open={open}
      onCancel={close}
      getInitialData={() => ({
        text: form.getFieldValue(itemField("tooltip")),
        ...(value ?? {}),
      })}
      onOk={(fields) => {
        onChange?.(fields);
        close();
        form.setFieldValue(itemField("tooltip"), fields.text);
      }}
      tooltipText={tooltipText}
    />
  );
};

ExtTooltipItem.defaultProps = {
  value: undefined,
  onChange: undefined,
};
