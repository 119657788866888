// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__threeRows--G5tFL {
  grid-row: span 3;
}
.src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__passBlock--L67PD {
  grid-row: span 3;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__emptyBlock--DS61G {
  grid-row: span 3;
}
.src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__rule--JpUoX {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserAccountPage/PasswordChangeBlock/PasswordChangeBlock.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACA;EAFI,gBAAA;EAIA,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EARI,gBAAA;AAUJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ","sourcesContent":[".threeRows {\n    grid-row: span 3;\n}\n.passBlock {\n    .threeRows;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n.emptyBlock {\n    .threeRows;\n}\n.rule {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"threeRows": `src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__threeRows--G5tFL`,
	"passBlock": `src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__passBlock--L67PD`,
	"emptyBlock": `src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__emptyBlock--DS61G`,
	"rule": `src-pages-UserAccountPage-PasswordChangeBlock-PasswordChangeBlock-module__rule--JpUoX`
};
export default ___CSS_LOADER_EXPORT___;
