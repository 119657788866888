import * as React from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { newItemId, ObjectO2 } from "../../Obj2Nodes";
import { Obj2TabStore } from "../..";
import { ObjectCopyModal } from "./ObjectCopyModal/ObjectCopyModal";

interface PropsOtherObjectFnMenu {
  buttonId: string;
  objNode: ObjectO2;
  store: Obj2TabStore;
}

export const OtherObjectFnMenu: React.FC<PropsOtherObjectFnMenu> = observer(
  (props) => {
    const { buttonId, store, objNode } = props;
    const isNew = objNode.object.id === newItemId;
    const canDelete = objNode.actions.delete;
    const [openCopy, setOpenCopy] = React.useState(false);
    const menuProps: MenuProps = {
      items: [],
    };
    if (!isNew) {
      menuProps.items?.push({
        key: "copy",
        label: "Скопировать объект",
        icon: <CopyOutlined />,
        onClick: () => setOpenCopy(true),
      });
    }
    if (canDelete) {
      menuProps.items?.push({
        key: "delete",
        label: "Удалить объект",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => store.safeDeleteCurNode(),
        // id="deleteCurObject"
      });
    }
    return (
      <>
        <Dropdown menu={menuProps}>
          <Button id={buttonId} icon={<EllipsisOutlined />} />
        </Dropdown>
        <ObjectCopyModal
          open={openCopy}
          close={() => setOpenCopy(false)}
          objNode={objNode}
          store={store}
        />
      </>
    );
  },
);
