import React from "react";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { AttrTypeMap } from "src/common/attributes";
import { ZAttrViewInfo } from "src/common/attrView";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { getSeparator } from "src/common/attrView/viewFormItems/Separator";
import styles from "./GroupCell.module.less";

type PropsGroupCell = {
  attrMetaList: ZAttrMeta[];
  attrTypeMap: AttrTypeMap;
  viewInfoMap: Record<string, ZAttrViewInfo | undefined>;
  row: Record<string, string[]>;
};

export const GroupCell: React.FC<PropsGroupCell> = ({
  attrMetaList,
  attrTypeMap,
  viewInfoMap,
  row,
}) => {
  const labels = attrMetaList
    .map((attrMeta) => {
      const vals = row[attrMeta.id];
      if (!vals || vals.length === 0) return null;
      const viewInfo = viewInfoMap[attrMeta.id || ""];
      const separator = getSeparator(
        viewInfo?.appearance?.column?.group?.separator,
      );
      return [createItemView(attrMeta, attrTypeMap, vals, viewInfo), separator];
    })
    .filter((e) => e?.[0]);

  // полследним всегда будет разделитель, он не нужен
  const labelsWithSeparator = labels.flat();
  labelsWithSeparator.pop();
  return <div className={styles.box}>{labelsWithSeparator}</div>;
};
