import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { LayoutWithSider, AddButton } from "src/components/LayoutWithSider";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ModalDelete } from "src/components/ModalDelete";
import { TreeViewO2 } from "./TreeViewO2";
import { Obj2TabStore } from "./Obj2TabStore";
import { ObjNodeEditor } from "./ObjNodeEditor";
import { Obj2Content } from "./Obj2Content";
import styles from "./Obj2Tab.module.less";

interface PropsObj2Tab {
  store: Obj2TabStore;
}

export const Obj2Tab: React.FC<PropsObj2Tab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  const Inner: React.FC = observer(() => (
    <LayoutWithSider
      siderHeader={
        <AddButton
          onClick={() => store.safeCreateObject()}
          id="addObject"
          text="Добавить объект"
        />
      }
      siderContent={<TreeViewO2 store={store} />}
    >
      <LoaderBox
        remoteData={store.nodeData}
        drawReady={() => <MainPart store={store} />}
      />
    </LayoutWithSider>
  ));
  return <LoaderBox remoteData={store.data} drawReady={() => <Inner />} />;
});

/* eslint react/jsx-no-useless-fragment: "off"  */

const MainPart: React.FC<PropsObj2Tab> = observer(({ store }) => (
  <VerticalLayout>
    {!!store.curNode && (
      <div className={styles.box}>
        {store.curNode.type === "obj" ? (
          <Obj2Content node={store.curNode} store={store} />
        ) : (
          <ObjNodeEditor curNode={store.curNode} store={store} />
        )}
      </div>
    )}
    <ModalDelete
      open={!!store.deletingNode}
      message={store.messageAboutDelete}
      waiting={store.deletingWait}
      onOk={() => store.doDelete()}
      onCancel={() => store.setDeletingNode(null)}
    />
  </VerticalLayout>
));
