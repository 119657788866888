import { AttrViewStylesNames } from "./ZAttrViewStyles";
import { ZAttrComponentView } from "./zAttrViewInfo";

export const viewStylesByType = {
  SimpleText: ["fontWeight", "textAlign"],
  PersonCellInfo: ["fontWeight", "textAlign"],
  DictRef: ["fontWeight", "textAlign"],
  ObectRef: ["fontWeight", "textAlign"],
  ImgList: [],
  LinkStd: ["fontWeight", "textAlign"],
  LinkView: [],
  Tag: ["fontWeight", "textAlign"],
  FileList: ["fontWeight", "textAlign"],
  ContentLangInfo: [],
} satisfies Record<ZAttrComponentView["view"], AttrViewStylesNames[]>;
