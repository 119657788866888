import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { UploadFile } from "antd";
import {
  getRoomInfo,
  sendMessage as sendMessageApi,
  sendMessageWithFile as sendMessageWithFileApi,
} from "../apiChat";
import {
  RCSChangeEvent,
  ZRCMessage,
  ZRCRoom,
} from "../rocketChat/rocketChat.types";
import {
  rcMessageLoader,
  RCMessageLoaderParams,
} from "../rocketChat/rcMessageLoader";
import { RCEventListener } from "../rocketChat/interface";
import { ZChatInfo } from "../bsChat.types";

export type RCFormData = {
  message: string;
  // описание файла
  description: string;
  file: UploadFile;
};

export class ChatFormStore implements RCEventListener {
  chatInfo: ZChatInfo | null = null;

  setChatInfo(info: ZChatInfo) {
    this.chatInfo = info;
  }

  get chatId() {
    return this.chatInfo?.id || "";
  }

  /**
   * Название чата внутри нашей системы
   */
  get chatName() {
    return this.chatInfo?.name || "";
  }

  get membersCount() {
    return this.chatInfo?.userIds.length || 0;
  }

  roomInfo: ZRCRoom | null = null;

  private setRoomInfo(info: ZRCRoom) {
    this.roomInfo = info;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  replyMsgId = "";

  setReplyMsgId(id: string) {
    this.replyMsgId = id;
  }

  messageList: ZRCMessage[] = [];

  setMessageList(list: ZRCMessage[]) {
    this.messageList = list;
  }

  /**
   * На данный момент допускаем только обычные сообщения без типа
   */
  get allowedMessages() {
    return this.messageList.filter((msg) => !msg.t).reverse();
  }

  // пока что пагинация не проработана, загружаем все пачкой, делая такое предположения
  loadParams: RCMessageLoaderParams = { count: 10000, offset: 0 };

  setLoadParams(params: RCMessageLoaderParams) {
    this.loadParams = params;
  }

  constructor(info: ZChatInfo) {
    this.setChatInfo(info);
    makeAutoObservable(this);
  }

  async init() {
    try {
      this.setLoading(true);
      getRoomInfo(this.chatId).then((data) => {
        this.setRoomInfo(data);
      });
      this.loadMessages();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadMessages() {
    try {
      const messages = await rcMessageLoader(this.chatId, this.loadParams);
      this.setMessageList(messages);
    } catch (error) {
      onError(error);
    }
  }

  async sendMessage(data: RCFormData) {
    if (data.file) {
      return sendMessageWithFileApi(this.chatId, data.file, data.message);
    }
    return sendMessageApi(this.chatId, data.message);
  }

  onNewMessage(msgEvent: RCSChangeEvent) {
    if (msgEvent.fields.eventName === this.chatId)
      this.setMessageList([...msgEvent.fields.args, ...this.messageList]);
  }
}
