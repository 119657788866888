// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__tableRowClickable--sL770:hover {
  background-color: #ececec;
}
.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__tableRow--st8wg {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  min-width: fit-content;
}
.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__rowItem--ebtTa {
  flex: 1;
  padding: 16px 24px;
  text-align: left;
  overflow: hidden;
  min-width: 150px;
}
.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__selectionRow--yfn06 {
  flex: none;
  min-width: unset;
}
.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__selectionRow--yfn06 > * {
  width: 16px;
  height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/templates/ATVRowStd/ATVRowStd.module.less"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAGA;EACE,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,WAAA;EACA,sBAAA;AADF;AAIA;EACE,OAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAFF;AAKA;EACE,UAAA;EACA,gBAAA;AAHF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ","sourcesContent":[".tableRowClickable {\n  &:hover {\n    background-color: rgb(236, 236, 236);\n  }\n}\n.tableRow {\n  display: flex;\n  flex-direction: row;\n  border-bottom: 1px solid #e2e2e2;\n  width: 100%;\n  min-width: fit-content;\n}\n\n.rowItem {\n  flex: 1;\n  padding: 16px 24px;\n  text-align: left;\n  overflow: hidden;\n  min-width: 150px;\n}\n\n.selectionRow {\n  flex: none;\n  min-width: unset;\n  & > * {\n    width: 16px;\n    height: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRowClickable": `src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__tableRowClickable--sL770`,
	"tableRow": `src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__tableRow--st8wg`,
	"rowItem": `src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__rowItem--ebtTa`,
	"selectionRow": `src-pages-EntityFiltersPage-AsyncTableView-templates-ATVRowStd-ATVRowStd-module__selectionRow--yfn06`
};
export default ___CSS_LOADER_EXPORT___;
