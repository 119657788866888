import React from "react";
import { ZRCAttachemntBase } from "src/businessServices/services/bsChat/rocketChat/rocketChat.types";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import styles from "./Attachment.module.less";
import { createFileAccessUrl } from "./createFileAccessUrl";

type PropsBaseAttachment = { data: ZRCAttachemntBase };

export const BaseAttachment: React.FC<PropsBaseAttachment> = ({ data }) => (
  <div className={classNames([styles.attachmentCard, styles.baseAttCard])}>
    <div>{data.title}</div>
    <Button href={createFileAccessUrl(data.title_link)} download={data.title}>
      <DownloadOutlined />
    </Button>
  </div>
);
