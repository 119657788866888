import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { anchorItem, AnchorItem } from "src/common/anchorItem";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { blocksList } from "src/components/FormWithBlocks/blockTypes";
import { RemoteData } from "src/common/RemoteData";
import { zObjectItem, ZObjectItem } from "src/types/ZObjectItem";
import { getIdLabels, IdLabel } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { appStore } from "src/appStore";
import { makeBlockId } from "src/components/FormWithBlocks/blockTypes/makeBlockId";
import { apiAuthUrl, apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { langHdr, optionalLangParams } from "src/lang/langHdr";
import { AxiosError } from "axios";
import { passwordChangeBlock } from "./PasswordChangeBlock";
import { buildMnenoGroup } from "../EntityCardPage/blockBuilder/buildMnemoGroup";
import { BuilderCtx } from "../EntityCardPage/blockBuilder/BuilderCtx";
import { blockTitle } from "../EntityCardPage/blockBuilder/blockTitle";
import {
  zPersonEntity,
  ZPersonEntity,
} from "../ManagementPage/PersonsTab/ZPersonEntity";
import { EdPasswordChangeForm } from "./PasswordChangeBlock/EdPasswordChange";

type PersonData = {
  roleObject: ZObjectItem;
  person: ZPersonEntity;
};

export class UserAccountPageStore {
  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.attrTypesList.length === 0) {
        this.setAttrTypesList(await getIdLabels("attrType", "attrType"));
      }
      const { roleId: userRoleId, id: userId } = appStore.userInfo;

      // Договорились, что в запрос идёт roleId из /current.
      const langParams = optionalLangParams(true);
      const resp = await rest.get(
        apiObjUrl(`/roles/${userRoleId}`),
        langParams,
      );
      const roleObject = zObjectItem.parse(resp.data);

      const resp1 = await rest.get(apiObjUrl("/users"), {
        params: { userId },
        ...langParams,
      });
      const person = zPersonEntity.parse(resp1.data);

      this.setData({ status: "ready", result: { roleObject, person } });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  get initialData() {
    const result: Record<string, string[] | null> = {};
    if (this.data.status === "ready") {
      const { attributeValues } = this.data.result.person;
      attributeValues.forEach(({ attributeId, values }) => {
        result[String(attributeId)] = values;
      });
    }
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  async changePassword(values: EdPasswordChangeForm) {
    const { oldPass: currentPass, newPass } = values.password ?? {};
    if (!currentPass) throw Error("Old password not specified");
    if (!newPass) throw Error("New password not specified");
    try {
      await rest.put(
        apiAuthUrl("/reset-password"),
        {
          currentPassword: currentPass,
          newPassword: newPass,
        },
        {
          headers: langHdr(),
        },
      );
    } catch (e) {
      // LPLM-1241 Костыль, т.к. на бэке не могут обработать эту ситуацию.
      const ex = e as AxiosError;
      if (ex.response?.status === 401) {
        throw Error(t("Incorrect current password"));
      }
      throw e;
    }
    return values;
  }

  attrTypesList: IdLabel[] = [];

  setAttrTypesList(list: IdLabel[]) {
    this.attrTypesList = list;
  }

  get attrTypesDict(): Record<number, string> {
    return makeDictNameById(this.attrTypesList);
  }

  data: RemoteData<PersonData> = { status: "none" };

  setData(newData: RemoteData<PersonData>) {
    this.data = newData;
  }

  formStore = new FormWithBlockStore();

  get rootBlock(): FormBlockDef | null {
    const { data } = this;
    if (data.status !== "ready") return null;
    const { roleObject } = data.result;
    const ctx: BuilderCtx = {
      typesMap: this.attrTypesDict,
      canUpdate: false,
    };
    return blocksList("root", [
      buildMnenoGroup(
        "info",
        t("General information"),
        roleObject.attributes,
        null,
        ctx,
      ),
      passwordChangeBlock(),
    ]);
  }

  get anchors(): AnchorItem[] {
    const blocks = (this.rootBlock?.subBlocks ?? []) as FormBlockDef[];
    return blocks.map(({ key, title }) =>
      anchorItem(`#${makeBlockId(key)}`, blockTitle(title)),
    );
  }
}

export const userAccountPageStore = new UserAccountPageStore();
