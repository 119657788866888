import { ZAttribute } from "src/types/ZAttribute";
import { saveTranslation } from "src/common/api/apiTranslation";
import {
  AttributeO2,
  CommonNodeO2,
  GroupO2,
  ZLightGroup,
  newItemId,
  visitO2,
  zLightGroup,
} from "../Obj2Nodes";
import { findNodeOwnerByKey } from "../../../../common/findNodeOwnerByKey";
import {
  createAttrInGroup,
  createAttrInValue,
  createAttributeInObject,
  createGroupInGroup,
  createGroupInObj,
  createGroupInValue,
  saveRestrictions,
  updateAttribute,
  updateGroup,
} from "../../objectsApi";
import { EdAttribute, edit2attr } from "../EdAttribute";
import { saveAttributePermissions } from "../roles/rolesApi";
import { EdGroup } from "../EdGroup";

// Перехватывать ошибки не надо, т.к. это происохдит в ModelessForm

export const commonAttrSave = async (
  attrNode: AttributeO2,
  values: EdAttribute,
  treeData: CommonNodeO2[],
): Promise<{
  owner: CommonNodeO2;
  attr: ZAttribute;
}> => {
  const oldName = attrNode.attr.name;
  const {
    attr: validValues,
    restrictions,
    rolesMap,
    translations = {},
  } = edit2attr(values);
  const srcAttr: ZAttribute = { ...attrNode.attr, ...validValues };
  const { id, ...otherFields } = srcAttr;
  const isNewAttr = id === newItemId;
  const res = findNodeOwnerByKey(attrNode.key, treeData);
  if (!res) throw Error("Не найден владелец атрибута");
  const dstAttr = await visitO2<Promise<ZAttribute>>(res.owner, {
    attr: async () => {
      throw Error("Атрибут не может принадлежать атрибуту");
    },
    obj: async (it) =>
      isNewAttr
        ? createAttributeInObject(it.object.id, otherFields)
        : updateAttribute(srcAttr),
    group: async (it) =>
      isNewAttr
        ? createAttrInGroup(it.group.id, otherFields)
        : updateAttribute(srcAttr),
    value: async (it) => {
      // Если атрибут принадлежит значению - POST /api/groups/{id}/values/{valueId}/attributes
      if (isNewAttr) {
        return createAttrInValue(it.groupId, it.value.id, otherFields);
      }
      return updateAttribute(srcAttr);
    },
  });
  if (restrictions) {
    await saveRestrictions(dstAttr.id, restrictions);
  }
  await Promise.all(
    Object.entries(rolesMap).map(
      ([stateId, permissions]) =>
        saveAttributePermissions(dstAttr.id, stateId, permissions),
      saveTranslation(oldName, {
        value: dstAttr.name,
        translations,
      }),
    ),
  );
  return { attr: dstAttr, owner: res.owner };
};

export const commonGroupSave = async (
  groupNode: GroupO2,
  values: unknown,
  treeData: CommonNodeO2[],
): Promise<ZLightGroup> => {
  const oldName = groupNode.group.name;
  const { translations, ...groupValues } = values as EdGroup;
  const validValues = zLightGroup.partial().parse(groupValues);
  const srcGroup = { ...groupNode.group, ...validValues };
  const { id, ...otherFields } = srcGroup;
  const isNewAttr = id === newItemId;
  const res = findNodeOwnerByKey(groupNode.key, treeData);
  if (!res) throw Error("Не найден владелец группы");
  const result = await visitO2<Promise<ZLightGroup>>(res.owner, {
    attr: () => {
      throw Error("Атрибут не может быть владельцем группы");
    },
    obj: async (it) =>
      isNewAttr
        ? createGroupInObj(it.object.id, otherFields)
        : updateGroup(srcGroup),
    group: (it) =>
      isNewAttr
        ? createGroupInGroup(it.group.id, otherFields)
        : updateGroup(srcGroup),
    value: async (it) => {
      if (isNewAttr) {
        return createGroupInValue(it.groupId, it.value.id, otherFields);
      }
      return updateGroup(srcGroup);
    },
  });
  await saveTranslation(oldName, {
    value: result.name,
    translations,
  });
  return result;
};
