import * as React from "react";
import { AttrPersonSelectProps } from "src/common/attrEdit/components/ZAttrPersonSelect";
import { ZAttribute } from "src/types/ZAttribute";
import { ZPersonRow } from "src/types/ZPersonRow";
import { Select } from "antd";
import { apiLoadPersonList } from "src/common/apiPerson";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { PersonLabel } from "src/common/attrView/components/PersonCellInfo";
import { DefaultOptionType } from "antd/es/select";
import { loadRolesDictCached } from "src/common/apiRoles";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";

export type PropsPersonSelect = AttrPersonSelectProps & {
  value?: string[] | null;
  rolesViewAtts?: ZRolesViewAtts;
  onChange?(newValue?: string[] | null): void;
  attr: ZAttribute;
  disabled?: boolean;
  allowClear?: boolean;
};

export const PersonSelect: React.FC<PropsPersonSelect> = observer((props) => {
  const {
    value,
    onChange,
    disabled,
    attr,
    multiple,
    allowClear,
    rolesViewAtts,
  } = props;
  const roleIds = attr.roleIds ?? [];
  const [loading, setLoading] = React.useState(false);
  const [persons, setPersons] = React.useState<ZPersonRow[]>([]);
  const [rolesDict, setRolesDict] = React.useState<Record<number, ZObjectItem>>(
    {},
  );
  const init = async () => {
    try {
      setLoading(true);
      const rows = await apiLoadPersonList({ roleIds, userIds: [] });
      const dict = await loadRolesDictCached();
      setRolesDict(dict);
      setPersons(rows);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    init();
  }, []);

  const options: DefaultOptionType[] = persons.map((person) => ({
    label: (
      <PersonLabel
        key={person.id}
        person={person}
        rolesViewAtts={rolesViewAtts}
        rolesDict={rolesDict}
      />
    ),
    value: person.userId,
  }));

  return (
    <Select
      value={value}
      loading={loading}
      options={options}
      disabled={disabled}
      mode={multiple ? "multiple" : undefined}
      allowClear={allowClear}
      onChange={onChange}
    />
  );
});

PersonSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
  allowClear: false,
  rolesViewAtts: undefined,
};
