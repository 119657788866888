import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import {
  loadObjectAttrinbutesAll,
  loadObjects,
} from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectItem } from "src/types/ZObjectItem";

export type MCObjType =
  | "MChart"
  | "MCPoint"
  | "Pt"
  | "SizeLines"
  | "SizeLineSize"
  | "SizeName";

export const mChartSettings = makeAutoObservable({
  async init() {
    try {
      this.setLoading(true);
      this.setObjects(await loadObjects());
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  },
  loading: false,
  setLoading(value: boolean) {
    this.loading = value;
  },
  objects: [] as ZObjectItem[],
  setObjects(list: ZObjectItem[]) {
    this.objects = list;
  },
  attrsMap: {} as Record<MCObjType, ZAttribute[]>,
  setAttrs(key: MCObjType, list: ZAttribute[]) {
    this.attrsMap[key] = list;
  },
  loadingAttrs: new Set<MCObjType>(),
  setLoadingAttrs(type: MCObjType) {
    this.loadingAttrs.add(type);
  },
  unsetLoadingAttrs(type: MCObjType) {
    this.loadingAttrs.delete(type);
  },
  async loadAttrs(key: MCObjType, objId: number | undefined) {
    try {
      this.setLoadingAttrs(key);
      this.setAttrs(key, []);
      if (objId) this.setAttrs(key, await loadObjectAttrinbutesAll(objId));
    } catch (e) {
      onError(e);
    } finally {
      this.unsetLoadingAttrs(key);
    }
  },
});

export type MChartSettings = typeof mChartSettings;
