import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  Button,
  Collapse,
  Flex,
  Form,
  FormRule,
  Input,
  InputRef,
  Modal,
  Tabs,
  Tooltip,
} from "antd";
import { delay } from "src/common/delay";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { ZObjState } from "src/types/ZObjState";
import { onError } from "src/common/onError";
import { ifDef } from "src/common/ifDef";
import { FormInstance } from "rc-field-form";
import { MultiLangFields } from "src/components/MultiLangFields/MultiLangFields";
import { VerticalContent } from "src/components/VerticalContent";
import { FieldsWithTitle } from "src/pages/ManagementPage/FieldsWithTitle";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { ExtraTools } from "src/pages/ManagementPage/ExtraTools";
import {
  CommonNodeO2,
  ObjectO2,
  ZLightObject,
  newItemId,
} from "../../Obj2Nodes";
import { Obj2TabStore } from "../../Obj2TabStore";
import { RolesEditor } from "../../roles/RolesEditor";
import { ZPermissions } from "../../roles/roleTypes";
import { createObjectState } from "../../roles/rolesApi";
import { createPermissions } from "../../roles/createPermissions";
import { EdObject } from "../../EdObject";
import { NotifyTemplates } from "../../NotifyTemplates";
import { AddObjectItem } from "./AddObjectItem";
import styles from "./ObjectForm2.module.less";
import { OtherObjectFnMenu } from "./OtherObjectFnMenu";

interface PropsObjectForm2 {
  store: Obj2TabStore;
  objectNode: ObjectO2;
}

const fieldStates: keyof EdObject = "states";

export const ObjectForm2: React.FC<PropsObjectForm2> = observer(
  ({ store, objectNode }) => {
    const { object } = objectNode;
    const ref1 = React.useRef<InputRef>(null);
    const [activeStateId, setActiveStateId] = React.useState<
      string | undefined
    >();
    const form = Form.useFormInstance();
    React.useEffect(() => {
      delay(50).then(() => ref1.current?.focus());
    }, [store.curNode]);
    React.useEffect(() => {
      delay(10)
        .then(() => {
          const curStates: ZObjState[] | undefined =
            form.getFieldValue(fieldStates);
          setActiveStateId(ifDef(curStates?.[0]?.id, (id) => String(id)));
        })
        .catch(onError);
    }, [object.id]);
    const states: ZObjState[] | undefined = Form.useWatch(fieldStates);
    const [modal, setModal] = React.useState<"add" | "delete" | undefined>();
    const disabled = !objectNode.actions.delete || object.id !== newItemId;
    const { roleGroups } = store;
    const [wait, setWait] = React.useState(false);
    const addState = async (name: string) => {
      try {
        setWait(true);
        const { state, permissions } = await addObjState(
          object.id,
          form,
          name,
          store,
        );
        setModal(undefined);
        form.setFieldValue(["rolesMap", String(state.id)], permissions);
        form.validateFields([["rolesMap", String(state.id)]]);
        setActiveStateId(String(state.id));
      } catch (e) {
        onError(e);
      } finally {
        setWait(false);
      }
    };
    const deleteState = (id: number) => {
      const newStateId = deleteObjState(form, id);
      if (String(id) === activeStateId) setActiveStateId(String(newStateId));
    };
    const toggleState = (key: string[] | string) => {
      if (Array.isArray(key)) {
        if (key.length === 0) {
          setActiveStateId(undefined);
        } else {
          setActiveStateId(key[0]!);
        }
      }
    };

    return (
      <WidthLimitedFields>
        <FieldsWithTitle title="Название объекта">
          <Form.Item
            name="name"
            label="Системное название"
            rules={[trimRequired(), uniqueObjName(object, store.treeData)]}
          >
            <Input ref={ref1} allowClear disabled={disabled} />
          </Form.Item>
          <MultiLangFields basePath={["translation"]} />
        </FieldsWithTitle>

        <Form.Item name={fieldStates} hidden />
        {!states && (
          <Alert
            type="warning"
            message="Права можно редактировать только после создания объекта"
          />
        )}

        {!!states && (
          <VerticalContent gap={12}>
            <Flex gap={12} justify="space-between" align="center">
              <h3>Состояние объекта</h3>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setModal("add");
                  setTimeout(
                    () => document.getElementById("addObjState_name")?.focus(),
                    50,
                  );
                }}
              >
                Добавить состояние...
              </Button>
            </Flex>
            <Collapse
              style={{ overflowX: "hidden" }}
              accordion
              activeKey={activeStateId}
              onChange={toggleState}
              items={states.map(({ id, name, objectId }) => ({
                key: id,
                label: `${name} (${id})`,
                children: (
                  <Tabs
                    items={[
                      {
                        key: "permissions",
                        label: "Права доступа",
                        children: (
                          <Form.Item name={["rolesMap", String(id)]}>
                            <RolesEditor
                              columns={store.objectTypeActions}
                              rows={roleGroups}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        key: "templates",
                        label: "Шаблоны уведомлений",
                        children: (
                          <NotifyTemplates
                            stateId={String(id)}
                            roles={roleGroups}
                          />
                        ),
                      },
                    ]}
                  />
                ),
                extra: objectId ? (
                  <Tooltip title="Удалить состояние">
                    <Button
                      size="small"
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteState(id);
                      }}
                    />
                  </Tooltip>
                ) : null,
              }))}
            />
          </VerticalContent>
        )}
        <Modal
          title="Добавить состояние"
          open={modal === "add"}
          onCancel={() => setModal(undefined)}
          destroyOnClose
          okButtonProps={{ htmlType: "submit", loading: wait }}
          okText="Добавить"
          cancelButtonProps={{ disabled: wait }}
          modalRender={(content) => (
            <Form
              name="addObjState"
              onFinish={({ name }) => addState(name)}
              layout="vertical"
              initialValues={{ name: "" }}
            >
              {content}
            </Form>
          )}
        >
          <Form.Item
            name="name"
            label="Название состояния"
            rules={[{ required: true }, uniqueState(states ?? [])]}
          >
            <Input />
          </Form.Item>
        </Modal>
      </WidthLimitedFields>
    );
  },
);

const addObjState = async (
  objectId: number,
  form: FormInstance,
  name: string,
  store: Obj2TabStore,
): Promise<{ state: ZObjState; permissions: ZPermissions }> => {
  const oldStates: ZObjState[] = form.getFieldValue(fieldStates) ?? [];
  const state: ZObjState = await createObjectState(objectId, name);
  const newStates = [...oldStates, state];
  form.setFieldValue(fieldStates, newStates);
  return {
    state,
    permissions: createPermissions(store.typeActions, store.roleGroups),
  };
};
const deleteObjState = (form: FormInstance, stateId: number): number => {
  const oldStates: ZObjState[] = form.getFieldValue(fieldStates) ?? [];
  const newStates = oldStates.filter(({ id }) => id !== stateId);
  form.setFieldValue(fieldStates, newStates);
  const id0 = newStates[0]?.id;
  return id0 || stateId;
};

const uniqueState = (states: ZObjState[]): FormRule => ({
  validator: (rule, value): Promise<void> => {
    const res = states.find(({ name }) => name === value.trim());
    return res
      ? Promise.reject(Error("Такое имя уже есть"))
      : Promise.resolve();
  },
});

const uniqueObjName = (
  srcObject: ZLightObject,
  objectsList: Readonly<CommonNodeO2[]>,
): FormRule => ({
  validator: (rule, value): Promise<void> => {
    const dst = objectsList.find(
      (node) =>
        node.type === "obj" &&
        node.object.id !== srcObject.id &&
        node.object.name === value,
    );
    return dst
      ? Promise.reject(Error("Объект с таким названием уже существует"))
      : Promise.resolve();
  },
});

export const ObjectFormButtons: React.FC<PropsObjectForm2> = observer(
  ({ store, objectNode }) => {
    const { object } = objectNode;
    const isNew = object.id === newItemId;
    return (
      <div className={styles.buttons}>
        {!isNew && (
          <Link
            className={styles.entitiesLink}
            to={makeUrl(PageUrl.entities, { objectId: object.id })}
          >
            Список экземпляров <ArrowRightOutlined />
          </Link>
        )}
        <ExtraTools>
          {!isNew && (
            <Link to={makeUrl(PageUrl.entityNew, { objectId: object.id })}>
              <Button icon={<FormOutlined />}>Создать экземпляр</Button>
            </Link>
          )}
          <AddObjectItem
            store={store}
            disabled={isNew}
            buttonId="addToObject"
            objNode={objectNode}
          />
          <OtherObjectFnMenu
            buttonId="otherObjectFunctions"
            objNode={objectNode}
            store={store}
          />
        </ExtraTools>
      </div>
    );
  },
);
