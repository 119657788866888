import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { onError } from "src/common/onError";
import { LabelComposeConfig } from "../../getActualView";
import { getPersonRefAttrLabel } from "./getPersonRefAttrLabel";

type PropsPersonRefInner = {
  person: ZEntity;
  attribute: ZAttribute;
  personIdList: string[];
  typeMap: Record<number, string>;
  // для отслеживания рекурсии
  pathRegister: number[];
  config?: LabelComposeConfig;
};

export const PersonRefInner: React.FC<PropsPersonRefInner> = ({
  attribute,
  person,
  personIdList,
  pathRegister,
  typeMap,
  config,
}) => {
  const [label, setLabel] = useState<React.ReactNode[] | null>(null);
  const init = async () => {
    try {
      setLabel(
        await getPersonRefAttrLabel(
          person,
          attribute,
          personIdList,
          typeMap,
          pathRegister || [],
          config,
        ),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return label;
};

PersonRefInner.defaultProps = { config: undefined };
