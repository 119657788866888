import * as React from "react";
import i18next, { t } from "i18next";
import { observer } from "mobx-react-lite";
import { getGlobalParams } from "src/common/globalParams";
import { LangSwitcher } from "src/components/LangSwitcher";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { Form } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { registerPageStore as pageStore } from "./RegisterPageStore";
import { RegFinish } from "./RegFinish";
import styles from "./RegisterPage.module.less";
import iconOmnidata from "./omnidata.svg";
import { EntityAnchor } from "../EntityCardPage/EntityFormWithAnchor/EntityAnchor";

interface PropsRegisterPage {
  param: string;
}
const idEntityFormContainer = "EntityFormContainer";

/**
 * Это специальная страница для регистрации новых участников бизнес-процесса.
 * ***Важно***, что она функционирует без авторизации.
 * Она не учитывается роутером. Поэтому её нет в PageUrl
 * На неё нельзя делать ссылки через <Link/>. Можно только через <a/>.
 * Ссылка на неё формируется функцией registerUrl
 * @see http://dev.plm.d4r.int:8078/external-registration-service/swagger-ui/index.html#/
 */
export const RegisterPage: React.FC<PropsRegisterPage> = observer(
  ({ param }) => {
    React.useEffect(() => {
      pageStore.init(param);
    }, [param]);
    const { state } = pageStore;
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <a className={styles.title} href={`/${i18next.language}/home`}>
            <img src={iconOmnidata} alt="" />
            <span>{getGlobalParams().title}</span>
          </a>
          <div className={styles.lang}>
            <LangSwitcher />
          </div>
        </div>
        {state === "input" && (
          <LoaderBox remoteData={pageStore.data} drawReady={() => <Inner />} />
        )}
        {state === "finish" && <RegFinish store={pageStore} />}
      </div>
    );
  },
);

const Inner: React.FC = observer(() => {
  const [form] = Form.useForm();
  const { block, anchors } = pageStore;
  if (!block) return null;
  return (
    <div className={styles.main}>
      <TShapeForm
        form={form}
        formProps={{
          name: "register",
          block,
          submitText: t("Register"),
          store: pageStore.formStore,
          submit: (values) => pageStore.submit(values),
          onSuccess: () => {
            pageStore.setState("finish");
          },
        }}
        title={pageStore.header}
        extraTitle={
          <span>{t("Enter information to register on the platform")}</span>
        }
        leftPart={
          <EntityAnchor
            anchors={anchors}
            containerSelector={`#${idEntityFormContainer}`}
          />
        }
        rightPartProps={{ id: idEntityFormContainer }}
      />
    </div>
  );
});
