import React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import styles from "./MessageList.module.less";
import { ChatFormStore } from "../ChatFormStore";
import { MsgGroup } from "./MsgGroup/MsgGroup";
import { msgList2MsgGroups } from "./MsgGroup/msgList2MsgGroups";

type PropsMessageList = {
  store: ChatFormStore;
};

export const MessageList: React.FC<PropsMessageList> = observer(({ store }) => {
  const msgGroups = msgList2MsgGroups(store.allowedMessages);
  const isEmpty = msgGroups.length === 0;
  return (
    <div className={styles.msgList}>
      {isEmpty ? (
        <Empty description="Cообщения в чате отсутствуют" />
      ) : (
        msgGroups.map(([key, group]) => (
          <MsgGroup msgGroup={group} key={key} store={store} />
        ))
      )}
    </div>
  );
});
