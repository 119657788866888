import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { onError } from "src/common/onError";
import { getObjectRefAttrLabel } from "../../getObjectRefAttrLabel";
import { LabelComposeConfig } from "../../getActualView";

type PropsObjectRefInner = {
  entity: ZEntity;
  attribute: ZAttribute;
  entityIdList: string[];
  typeMap: Record<number, string>;
  // для отслеживания рекурсии
  pathRegister: number[];
  config?: LabelComposeConfig;
};

export const ObjectRefInner: React.FC<PropsObjectRefInner> = ({
  attribute,
  entity,
  entityIdList,
  pathRegister,
  typeMap,
  config,
}) => {
  const [label, setLabel] = useState<React.ReactNode[] | null>(null);
  const init = async () => {
    try {
      setLabel(
        await getObjectRefAttrLabel(
          entity,
          attribute,
          entityIdList,
          typeMap,
          pathRegister || [],
          config,
        ),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return label;
};

ObjectRefInner.defaultProps = { config: undefined };
