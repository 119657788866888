import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadFile } from "antd";
import { UploadProps } from "antd/lib";
import React, { useEffect, useState } from "react";

type PropsRCUploader = Omit<UploadProps, "onChange"> & {
  onChange?: (file: UploadFile) => void;
  value?: UploadFile;
};

export const RCUploader: React.FC<PropsRCUploader> = ({
  onChange,
  value,
  ...props
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (value) {
      setFileList([value]);
    } else {
      setFileList([]);
    }
  }, [value]);

  const uploadCpmProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <Upload
      {...props}
      {...uploadCpmProps}
      onChange={(info) => onChange?.(info.file)}
    >
      <Button size="small" icon={<UploadOutlined />}>
        Прикрепить файл
      </Button>
    </Upload>
  );
};
RCUploader.defaultProps = {
  onChange: undefined,
  value: undefined,
};
