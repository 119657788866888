import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";

export const zChildEntities = z.object({
  editor: z.literal("ChildEntities"),
  attrId: z.string(),
});
export type ZChildEntities = z.infer<typeof zChildEntities>;

export const makeChildEntitiesProps = (
  attr: ZAttribute,
  editorInfo: ZChildEntities | undefined,
) => ({
  parentObjId: Number(attr.referenceId),
  editorInfo,
});
