import * as React from "react";
import styles from "./EntityGroupBlock.module.less";

interface PropsEntityGroupInnerBlock {
  title?: string;
  children?: React.ReactNode;
  id?: string;
}

export const EntityGroupInnerBlock: React.FC<PropsEntityGroupInnerBlock> = ({
  title,
  children,
  id,
}) => (
  <div className={styles.block} id={id}>
    {!!title && <h2 className={styles.title}>{title}</h2>}
    <div className={styles.items}>{children}</div>
  </div>
);

EntityGroupInnerBlock.defaultProps = {
  title: undefined,
  children: undefined,
  id: undefined,
};
