// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroup--jmih7 {
  display: flex;
  column-gap: 8px;
}
.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroupMsgList--wgrH2 {
  display: grid;
  row-gap: 10px;
}
.src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroupContent--t4DcA {
  width: 100%;
  padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/MessageList/MsgGroup/MsgGroup.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,aAAA;AAAF;AAEA;EACE,WAAA;EACA,mBAAA;AAAF","sourcesContent":[".msgGroup {\n  display: flex;\n  column-gap: 8px;\n}\n\n.msgGroupMsgList {\n  display: grid;\n  row-gap: 10px;\n}\n.msgGroupContent {\n  width: 100%;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msgGroup": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroup--jmih7`,
	"msgGroupMsgList": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroupMsgList--wgrH2`,
	"msgGroupContent": `src-businessServices-services-bsChat-ChatForm-MessageList-MsgGroup-MsgGroup-module__msgGroupContent--t4DcA`
};
export default ___CSS_LOADER_EXPORT___;
