import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { PageLayout } from "src/components/PageLayout";
import { LoaderBox } from "src/components/LoaderBox";
import { userAccountPageStore as store } from "./userAccountPageStore";
import { UserAccountForm } from "./UserAccountForm";

export const UserAccountPage: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <PageLayout breadcrumb={[]} pageTitle={t("UserAccount")}>
      <LoaderBox
        remoteData={store.data}
        drawReady={() => <UserAccountForm store={store} />}
      />
    </PageLayout>
  );
});
