import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { AsyncTableView } from "src/pages/EntityFiltersPage/AsyncTableView";
import {
  ZEntityRow,
  ZEntityFilters,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { makeAutoObservable } from "mobx";
import { compoundEntityTableStore } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore";
import { InputSearch } from "src/pages/EntityFiltersPage/EntityList/InputSearch";
import { Button, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { loadObject } from "src/pages/EntityCardPage/apiEntityCard";
import { t } from "i18next";
import { classNames } from "src/common/classNames";
import { ChangeStateBulkButton } from "src/pages/EntityFiltersPage/EntityList/ChangeStateBulkButton/ChangeStateBulkButton";
import { TableStore } from "../tables/TableStore";
import { ColumnsSettings } from "../tables/ColumnsSettings";
import {
  bindEventOnStorage,
  createStorageEventAction,
  unbindEventOnStorage,
} from "../../common/storageEventUtils";
import { LoaderBox } from "../LoaderBox";
import { ChildEntitiesEditor } from "./ChildEntitiesEditor";
import { ModalVertFixed } from "../ModalVertFixed";
import styles from "./ChildEntities.module.less";

export type ZEntityFiltersChild = ZEntityFilters & {
  parentEntityId?: number;
  parentLinkAttributeId?: number;
};

const createStore = () =>
  makeAutoObservable({
    objectId: 0,
    tableStore: null as TableStore<ZEntityRow, ZEntityFiltersChild> | null,
    async init(
      objectId: number,
      parentEntityId: number,
      parentLinkAttributeId: number,
    ) {
      this.objectId = objectId;
      const objectName = (await loadObject(objectId, { translate: true })).name;
      this.setCurrObjName(objectName);
      this.tableStore = await compoundEntityTableStore<
        ZEntityRow,
        ZEntityFiltersChild
      >(
        objectId,
        "id",
        {
          filters: { objectId, parentEntityId, parentLinkAttributeId },
        },
        { keepSelected: true, selectionType: "checkbox" },
      );
    },
    get isPopup(): boolean {
      return !!this.subCardStore;
    },
    subCardStore: null as EntityCardStore | null,
    setSubCardStore(store: EntityCardStore | null) {
      this.subCardStore = store;
    },
    openCreation(parentAttr: string, parentValue: number) {
      const store = new EntityCardStore();
      this.setSubCardStore(store);
      store.initNew(this.objectId, { [parentAttr]: [String(parentValue)] });
    },
    closeCreation() {
      this.setSubCardStore(null);
    },
    onUpdate() {
      this.tableStore?.reload().catch(onError);
      this.closeCreation();
    },
    currObjName: t("Add"),
    setCurrObjName(name: string) {
      this.currObjName = name;
    },
  });

type PropsChildEntities = {
  parentObjId: number;
  editorInfo: ZChildEntities | undefined;
  disabled?: boolean;
  entityId?: number;
};

export const ChildEntities: React.FC<PropsChildEntities> = observer(
  ({ parentObjId, editorInfo, disabled, entityId }) => {
    const [isHeightLimit, setIsHeightLimit] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const docHeight = document.documentElement.clientHeight;
    useEffect(() => {
      const element = tableRef?.current;
      if (!element) return undefined;
      const resizeObserver = new ResizeObserver(() => {
        if (element.scrollHeight / docHeight > 0.6) {
          setIsHeightLimit(true);
        }
      });
      resizeObserver.observe(element);
      return () => {
        resizeObserver.disconnect();
      };
    }, [tableRef?.current]);

    const navigate = useNavigate();
    const childEntitiesStore = useMemo(() => createStore(), []);
    const init = async () => {
      if (!entityId) return;
      try {
        await childEntitiesStore.init(
          parentObjId,
          entityId,
          Number(editorInfo?.attrId),
        );
      } catch (error) {
        onError(error);
      }
    };

    useEffect(() => {
      init();
      const handleReloadTable = createStorageEventAction(
        "lastUpdatedObject",
        (e: StorageEvent) => {
          try {
            const objId = Number(JSON.parse(e.newValue || '""'));
            if (objId === parentObjId) {
              childEntitiesStore.tableStore?.reload();
            }
          } catch (error) {
            onError(error);
          }
        },
      );
      bindEventOnStorage(handleReloadTable);
      return () => unbindEventOnStorage(handleReloadTable);
    }, []);

    if (!entityId || !editorInfo)
      return <div>{t("Display available only for created instances")}</div>;

    const { tableStore } = childEntitiesStore;
    if (!tableStore) return <Spin spinning>{t("Loading")}</Spin>;

    const { subCardStore, objectId } = childEntitiesStore;

    return (
      <div className={styles.box}>
        <div className={styles.header}>
          <div className={styles.search}>
            <InputSearch store={tableStore} disabled={disabled} />
          </div>
          <div className={styles.toolbar}>
            <ColumnsSettings store={tableStore} />
            <ChangeStateBulkButton store={tableStore} objectId={objectId} />
            <Button
              type="primary"
              size="middle"
              icon={<PlusCircleOutlined />}
              disabled={disabled}
              onClick={() =>
                childEntitiesStore.openCreation(editorInfo.attrId, entityId)
              }
            >
              {childEntitiesStore.currObjName}
            </Button>
          </div>
        </div>

        <div
          className={classNames([
            styles.tableContainer,
            [isHeightLimit, styles.heightLimited],
          ])}
          ref={tableRef}
        >
          <AsyncTableView<ZEntityRow, ZEntityFiltersChild>
            store={tableStore}
            columns={tableStore.columns}
            onRowClick={(row) => {
              navigate(makeUrl(PageUrl.entityCard, { id: row.id }));
            }}
            useHeader
            usePagination
            useSelection
          />
        </div>

        <ModalVertFixed
          open={childEntitiesStore.isPopup}
          onCancel={() => childEntitiesStore.closeCreation()}
          width="65vw"
          footer={null}
        >
          {subCardStore && (
            <LoaderBox
              remoteData={subCardStore.info}
              drawReady={() => (
                <ChildEntitiesEditor
                  store={subCardStore}
                  onSuccess={() => {
                    childEntitiesStore.onUpdate();
                  }}
                />
              )}
            />
          )}
        </ModalVertFixed>
      </div>
    );
  },
);
