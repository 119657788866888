import { Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { loadRefDict } from "src/common/loadRefDict";
import { onError } from "src/common/onError";
import { SimpleText } from "../SimpleText/SimpleText";
import { ZTagModeDictRef, ZDictRef } from "../../componentsDefs";
import { seedSeparators } from "../../viewFormItems/Separator";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./DictRef.module.less";

type PropsDictRef = {
  viewProps: ZDictRef | undefined;
  refId: number;
  values: string[];
  viewInfo?: ZAttrViewInfo;
};

const getTagsMultipleColor = (viewProps: ZDictRef | undefined, atrId: string) =>
  viewProps?.modeMeta?.tagsColorMap?.[atrId];

const getTagColor = (viewProps: ZDictRef | undefined) =>
  viewProps?.modeMeta?.tagColor;

const createMuliTag = (
  viewProps: ZDictRef | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  options: { id: number; value: string | null }[],
) => {
  const labelsWithSeparator = seedSeparators(
    options.map((opt) => ({
      content: (
        <Tag
          color={getTagsMultipleColor(viewProps, String(opt.id))}
          style={{ maxWidth: "100%" }}
        >
          <SimpleText values={[opt.value]} viewInfo={viewInfo} />
        </Tag>
      ),
      key: opt.id,
    })),
    viewInfo?.appearance?.view?.separator,
  );

  return (
    <div className={styles.box} style={viewInfo?.styles ?? undefined}>
      {labelsWithSeparator}
    </div>
  );
};

const createSimpleLable = (
  viewInfo: ZAttrViewInfo | undefined,
  options: { id: number; value: string | null }[],
) => {
  const labelsWithSeparator = seedSeparators(
    options.map((opt) => ({ content: opt.value, key: opt.id })),
    viewInfo?.appearance?.view?.separator,
  );
  return (
    <SimpleText
      values={labelsWithSeparator}
      viewInfo={viewInfo}
      className={styles.box}
    />
  );
};

const createTag = (
  viewProps: ZDictRef | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  options: { id: number; value: string | null }[],
) => (
  <Tag color={getTagColor(viewProps)} style={{ maxWidth: "100%" }}>
    {createSimpleLable(viewInfo, options)}
  </Tag>
);

const createLabel = (
  viewProps: ZDictRef | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  options: { id: number; value: string | null }[],
) => {
  const lablelMap: Record<ZTagModeDictRef, React.ReactNode> = {
    tagsMulti: createMuliTag(viewProps, viewInfo, options),
    tag: createTag(viewProps, viewInfo, options),
    standart: createSimpleLable(viewInfo, options),
  };
  const mode = viewProps?.mode;
  return mode ? lablelMap[mode] : lablelMap.standart;
};

export const DictRef: React.FC<PropsDictRef> = ({
  viewProps,
  refId,
  values,
  viewInfo,
}) => {
  const [label, setLabel] = useState<React.ReactNode | null>(null);
  const [loading, setLoading] = useState(false);
  const init = async () => {
    try {
      setLoading(true);
      const refOptList = await loadRefDict(refId, { translate: true });
      const dictKeys = new Set(values);
      const options = refOptList.filter((item) =>
        dictKeys.has(String(item.id)),
      );
      if (options.length === 0) return;
      const finalLabel = createLabel(viewProps, viewInfo, options);
      setLabel(finalLabel);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [values]);

  return loading ? <Spin spinning={loading}>...</Spin> : label;
};
DictRef.defaultProps = { viewInfo: undefined };
