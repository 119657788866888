import * as React from "react";
import { t } from "i18next";
import { getGlobalParams } from "src/common/globalParams";
import { Alert, Button, Divider, Flex, Form, Input } from "antd";
import { appStore } from "src/appStore";
import { getErrorMessage } from "src/common/onError";
import { ruleEmail } from "src/common/validationRules/ruleEmail";
import { observer } from "mobx-react-lite";
import { LoginOutlined } from "@ant-design/icons";
import { registerUrl } from "src/pages/RegisterPage/registerUrl";
import { CommonOverlay } from "../CommonOverlay";
import styles from "./LoginPage.module.less";

export const LoginPage: React.FC = observer(() => {
  const [touched, setTouched] = React.useState(false);
  const firstField = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    firstField.current?.focus();
    appStore.setDocumentTitle(getGlobalParams().title);
    setTouched(false);
  }, []);

  return (
    <CommonOverlay title={t("Login")}>
      <Form
        name="login"
        onFinish={({ login, password }) => {
          appStore.login(login, password);
          setTouched(true);
        }}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        size="large"
      >
        <Form.Item
          label={t("Enter email")}
          name="login"
          rules={[
            {
              required: true,
              message: t("The field is mandatory") || "",
            },
            ruleEmail(),
          ]}
          normalize={(value) => value.trim()}
        >
          <Input placeholder="Email" allowClear />
        </Form.Item>

        <Form.Item
          label={t("Password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("The field is mandatory") || "",
            },
          ]}
        >
          <Input.Password placeholder={t("Password") || ""} />
        </Form.Item>

        {touched && appStore.userData.status === "error" && (
          <Form.Item>
            <Alert
              type="error"
              showIcon
              {...getErrorMessage(appStore.userData.error)}
            />
          </Form.Item>
        )}

        <Button
          type="link"
          size="small"
          className={styles.resetPasswordLink}
          onClick={() => appStore.setOverlayType("passRestore")}
        >
          {t("Restore password")}
        </Button>

        <Flex vertical gap={12} className={styles.buttons}>
          <Form.Item>
            <Button
              loading={appStore.userData.status === "wait"}
              htmlType="submit"
              type="primary"
            >
              {t("Sign In")}
            </Button>
          </Form.Item>

          {appStore.extraSettings?.extRegUrl && (
            <Button
              disabled={appStore.userData.status === "wait"}
              href={registerUrl(appStore.extraSettings.extRegUrl)}
            >
              {t("Register")}
            </Button>
          )}

          {appStore.ssoAuthUrl && (
            <>
              <Divider>{t("Or")}</Divider>
              <Button
                href={appStore.ssoAuthUrl}
                disabled={appStore.userData.status === "wait"}
                icon={<LoginOutlined />}
              >
                {t("SSO Authorization")}
              </Button>
            </>
          )}
        </Flex>
      </Form>
    </CommonOverlay>
  );
});
