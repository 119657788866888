import { t } from "i18next";
import { z } from "zod";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { apiExtRegUrl } from "src/common/apiUrl";
import { getValidStateId } from "src/common/getValidStateId";
import { ZObjectItem } from "src/types/ZObjectItem";
import { loadAttrTypeMap } from "src/common/attributes";
import { AnchorItem } from "src/common/anchorItem";
import { langHdr, optionalLangParams } from "src/lang/langHdr";
import { rest } from "src/common/rest";
import { loadObject } from "../EntityCardPage/apiEntityCard";
import { zRegInfo, ZRegInfo, zRegSuccessMessage } from "./Register.types";
import { buildMainBlock } from "../EntityCardPage/blockBuilder/buildMainBlock";
import { BuilderCtx } from "../EntityCardPage/blockBuilder/BuilderCtx";
import { anchorList } from "../EntityCardPage/EntityFormWithAnchor/anchorList";

interface RegData {
  regInfo: ZRegInfo;
  objInfo: ZObjectItem;
  typesMap: Record<number, string>;
}

type RegState = "finish" | "input";

export const registerPageStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<RegData>,
  setData(newData: RemoteData<RegData>) {
    this.data = newData;
  },
  state: "input" as RegState,
  setState(s: RegState) {
    this.state = s;
  },
  settingsId: null as number | null,
  setSettingsId(id: number | null) {
    this.settingsId = id;
  },
  successMessage: null as string | null,
  setSuccessMessage(message: string) {
    this.successMessage = message;
  },
  async init(param: string) {
    try {
      this.setState("input");
      this.setData({ status: "wait" });
      const resp1 = await rest.get(
        apiExtRegUrl(`/registration/${param}`),
        optionalLangParams(true),
      );
      const regInfo = zRegInfo.parse(resp1.data);

      this.setSettingsId(regInfo.settingsId);
      // Другие запросы к сервисам можно делать только после этой строки, т.к. иначе не будет правильного проксирования урлов

      const objInfo = await loadObject(regInfo.objectId, {
        stateId: getValidStateId(regInfo.settings.stateId),
        translate: true,
      });
      const typesMap = await loadAttrTypeMap();

      this.setData({ status: "ready", result: { regInfo, objInfo, typesMap } });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },
  formStore: new FormWithBlockStore(),
  get block(): FormBlockDef | undefined {
    if (this.data.status !== "ready") return undefined;
    const { objInfo, typesMap, regInfo } = this.data.result;
    const ctx: BuilderCtx = {
      canUpdate: true,
      typesMap,
      stateId: regInfo.settings.stateId,
    };
    return buildMainBlock(objInfo, ctx);
  },
  get anchors(): AnchorItem[] {
    return anchorList(this.block);
  },
  get header(): string {
    const obj: string =
      this.data.status === "ready" ? this.data.result.objInfo.name : "---";
    return t("Registration of object", { obj });
  },
  async submit(formValues: unknown) {
    if (this.data.status !== "ready") throw Error("Data are not ready");
    const { settingsId } = this.data.result.regInfo;
    const zValues = z.record(
      z.string().regex(/^\d+$/),
      z.string().array().optional(),
    );
    const validValues = zValues.parse(formValues);
    const data = {
      settingsId,
      attributeValues: Object.entries(validValues).map(([key, values]) => ({
        attributeId: +key,
        values,
      })),
    };
    const resp = await rest.post(apiExtRegUrl("/registration"), data, {
      headers: langHdr(),
    });
    const { success, message } = zRegSuccessMessage.parse(resp.data);
    if (success) this.setSuccessMessage(message);
    return formValues;
  },
});

export type RegisterPageStore = typeof registerPageStore;
