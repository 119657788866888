// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__breakLine--O1R4x {
  word-break: break-all;
  white-space: normal;
}
.src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__elipsis--xTss0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__default--T32XU {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__box--aLBGq {
  width: 100%;
  display: inline-block;
}
.ant-tag:has(.src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__box--aLBGq) .src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__box--aLBGq {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/PersonCellInfo/PersonLabel/PersonLabel.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF;AACA;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AACF;AACA;EACE,WAAA;EACA,qBAAA;AACF;AACA;EAEI,cAAA;AAAJ","sourcesContent":[".breakLine {\n  word-break: break-all;\n  white-space: normal;\n}\n.elipsis {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.default {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.box {\n  width: 100%;\n  display: inline-block;\n}\n:global(.ant-tag):has(.box) {\n  .box {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakLine": `src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__breakLine--O1R4x`,
	"elipsis": `src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__elipsis--xTss0`,
	"default": `src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__default--T32XU`,
	"box": `src-common-attrView-components-PersonCellInfo-PersonLabel-PersonLabel-module__box--aLBGq`
};
export default ___CSS_LOADER_EXPORT___;
