// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-FileList-FileList-module__box--QQRnc {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/FileList/FileList.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,QAAA;AACF","sourcesContent":[".box {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-common-attrView-components-FileList-FileList-module__box--QQRnc`
};
export default ___CSS_LOADER_EXPORT___;
