import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import {
  loadMesChartSettings,
  saveMesChartSettings,
} from "./apiMeasurementChart";
import { MChartSettings } from "./MChartSettings";
import { ZMesChartSettings, zMesChartSettings } from "./MesChartTypes";

export const bsMeasurementChart: BusinessServiceDescriptor = {
  key: "Measurement chart",
  viewer() {
    return <div>Measurement Chart under construction</div>;
  },
  renderSettings() {
    return <MChartSettings />;
  },
  async loadSettings(info) {
    return loadMesChartSettings(info.id);
  },
  async saveSettings(info, values) {
    const data: ZMesChartSettings = {
      mcServiceId: info.id,
      objectId: info.objectId,
      ...zMesChartSettings
        .omit({ mcServiceId: true, objectId: true })
        .parse(values),
    };
    await saveMesChartSettings(info.id, data);
  },
};
